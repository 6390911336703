<template>
<main class=" p-2">
  <div class="row">
    <div class="col-sm-12">
      <div class="card " >
        <div class="card-body bg-primary shadow rounded" >
          <h2 class="card-title display-1 text-white m-5">VatID</h2>
          <a class="btn btn-light btn-lg m-3" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Go to Research</a>
          <!-- <router-link tag="a" to="/search" class="btn btn-light btn-lg m-3">Go to Research</router-link>-->
         </div>
       </div>
     </div>
   </div>

   <!-- -->

  <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Search</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <search-vat></search-vat>
        </div>
      </div>
    </div>
  </div>
  <!-- <a class="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a>-->

   <!-- -->
  <br>
  <div class="row">
    <div class="col-lg-6">
      <div class="card m-1 shadow rounded">
        <div class="card-body">
          <h3>Why Is a Scope this Portal?</h3>
          <br>
          <p class="fs-lg-4">
            On Web is not simply get a TaxID and Fiscal date the
            company by reseach by company name, This portal simplifies
            the games for European companies by making our database available
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 ">
      <div class="card m-1 shadow rounded">
        <div class="card-body">
          <h3>What can i look for?</h3>
          <div class="row">
            <div class="col-sm-5" style="border-right-color: #a9a9aa; border-right-style: solid; border-right-width: 2px">
              <transition name="list" tag="h3" class="display-1">
                <span v-bind:class="Flag(countries[nextPosition].id)" class=" display-1 flag-icon-item"></span>
              </transition>
              <hr>
             <transition name="list" tag="h6" class="display-6">
               <span class="list-item display-6">{{countries[nextPosition].verboseName}}</span>
             </transition>
            </div>
            <div class="col-sm-7 text-start">
              <transition-group name="list" tag="h4" class="VatcodeId">
                <span v-for="(ch, index) in textVatCode" v-bind:key="identific(ch,`${textVatCode[index].length}${ch}${index}${textVatCode[index]}`)" class="list-item">{{ch}}</span>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row ">
    <div class="col-lg-12">
      <div class="card m-1 shadow rounded ">
        <div class="card-body mt-4 mb-4">
          <h3 class="display-4">What are you waiting for?</h3>
          <router-link tag="a" to="/search" class="btn btn-primary btn-lg m-3">Go to Research</router-link>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-lg-6">
      <div class="card m-1 shadow rounded">
        <div class="card-body">
          <h3>Example the View data Company</h3>
          <h5 class="text-muted"><span class="flag-icon flag-icon-eu"></span> EU</h5>
          <h5 class="card-title"> <i class="fad fa-route-interstate"></i> EUXXXXXXXXXXX </h5>
          <h5 class="card-title"><i class="fad fa-id-card"></i> The Company Se</h5>
          <p class="card-text"><i class="fad fa-shield-check"></i> is Active : True</p>
          <p class="card-text"><i class="fad fa-city"></i> Louise Weiss building 1 Allée du Printemps  F-67070 Strasbourg cedex</p>
          <span v-html="compne"></span>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card m-1 shadow rounded">
        <div class="card-body">
          <h3 class="mb-5">The Security for you is active</h3>
          <figure class="figure col-9">
            <img :src="`${publicPath}look.svg`" class="figure-img img-fluid" alt="LookImage">
            <figcaption class="figure-caption text-end">Leave the data validation to us</figcaption>
          </figure>
          <!--<i class="fad fa-lock-alt lokk mt-3 mb-4"></i>-->
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-12">
      <div class="card shadow shadow-lg rounded">
        <div class="card-body">
          <h3 class="display-4">Thanks to the Tax ID you can really know with transparency who you are dealing with</h3>
          <router-link tag="a" to="/search" class="btn btn-primary btn-lg m-3">Go to Research</router-link>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-6 mt-2">
      <h3>The Price for This data ?</h3>
      <div class="card shadow shadow-lg rounded bg-primary">
        <div class="card-body text-light mt-5 mb-5">
          <h2 class="mt-2 display-2"> < 0,60 € </h2>
          <h4 class="display-6"> For View Company data (1 Credit)</h4>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mt-2">
      <h3 class="">How can I buy credits ?</h3>
      <div class="card shadow shadow-lg rounded bg-primary">
        <div class="card-body text-light mt-5 mb-5">
          <h4 class="display-6"> Simple by purchasing a top-up package like your mobile phone credit</h4>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-12">
      <div class="card shadow shadow-lg rounded">
        <div class="card-body">
          <h3 class="display-4">VatID.Cloud</h3>
          <router-link tag="a" to="/search" class="btn btn-primary btn-lg m-3">Go to Research</router-link>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row bgFooter text-light">
    <div class="col-lg-6">
      <div class="text-start m-4">
        <h4 class="display-4">VatID</h4>
        <h6>Project by</h6>
        <h6 class="">Pellizzaro Development Projects</h6>
        <h6>© 2020 - {{ new Date().getFullYear() }} - @VatID.Cloud - <a class="text-decoration-none text-light" href="mailto:info@vatid.cloud?subject=Information&body=Hello!" >info@vatid.cloud</a> - Europe</h6>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="text-start m-4 text-light">
        <ul class="list-unstyled list-group list-group-flush">
          <li class="">
            <router-link tag="a" to="/" class="fs-4 text-decoration-none text-light">Home</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/Login" class="fs-4 text-decoration-none text-light" >Login</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/Register" class="fs-4 text-decoration-none text-light" v-if="!betaState" >Register</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/Charge" class="fs-4 text-decoration-none text-light" v-if="!betaState" >Charge</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="text-start m-4 text-light">
        <ul class="list-unstyled list-group list-group-flush">
          <li class="">
            <router-link tag="a" to="/search" class="fs-4 text-decoration-none text-light" >Search by Name</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/searchByVat" class="fs-4 text-decoration-none text-light" >Search by Vat</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/user" class="fs-4 text-decoration-none text-light" >User</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/credit" class="fs-4 text-decoration-none text-light" >Credit User</router-link>
          </li>
          <li class="">
            <router-link tag="a" to="/history" class="fs-4 text-decoration-none text-light" >History User</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="text-start m-4 text-light">
        <ul class="list-unstyled list-group list-group-flush">
          <li class="">
            <router-link tag="a" to="/ticket" class="fs-4 text-decoration-none text-light" >Support</router-link>
          </li>
          <li class="">
            <a href="https://www.iubenda.com/privacy-policy/14075729" class="fs-4 text-decoration-none text-light" title="Privacy Policy ">Privacy Policy</a>
          </li>
          <li class="">
            <a href="https://www.iubenda.com/privacy-policy/14075729/cookie-policy" class="fs-4 text-decoration-none text-light">Cockies</a>
          </li>
          <li class="">
            <a href="https://www.iubenda.com/terms-and-conditions/14075729" class="fs-4 text-decoration-none text-light">Terms & Condition</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</main>



</template>

<script>
import * as GetISO from "@/helpers/convertToNationISO";

export default {
  name: 'Home',
  data () {
    return {
      flagclass : 'flag-icon flag-icon-',
      nextPosition : 0,
      textVatCode : '',
      linkMap: ``,
      compne : '',
      publicPath: process.env.BASE_URL,
      countries : [
        {id:"AT", verboseName:"Austria", taxCode : "AU-UID" },
        {id:"BE", verboseName:"Belgium", taxCode : "BE-BTW-nr"},
        {id:"BG", verboseName:"Bulgaria", taxCode : "BG-ДДС номер"},
        {id:"CY", verboseName:"Cyprus", taxCode : "CY-ΦΠΑ"},
        {id:"CZ", verboseName:"Czech Republic", taxCode : "CZ-DIČ"},
        {id:"DE", verboseName:"Germany", taxCode : "DE-USt-IdNr"},
        {id:"DK", verboseName:"Denmark", taxCode: "DK-CVR"},
        {id:"EE", verboseName:"Estonia", taxCode : "EE-KMKR"},
        {id:"EL", verboseName:"Greece", taxCode: "EL-Arithmós Mētrṓou Fi-Pi-A"},
        {id:"ES", verboseName:"Spain", taxCode : "ES-NIF"},
        {id:"FI" , verboseName:"Finland", taxCode : "FI-ALV nro"},
        {id:"FR", verboseName:"France", taxCode : "FR-TVA"},
        {id:"GB", verboseName:"United Kingdom", taxCode:"GB-VAT Reg No"},
        {id:"HR", verboseName:"Croatia", taxCode :"HR-PDV-ID OIB"},
        {id:"HU", verboseName:"Hungary", taxCode : "HU-ANUM"},
        {id:"IE", verboseName:"Ireland", taxCode : "IR-VAT"},
        {id:"IT", verboseName:"Italy", taxCode : "IT- Partita Iva"},
        {id:"LT", verboseName:"Lithuania", taxCode :"LT-PVM kodas"},
        {id:"LU", verboseName:"Luxembourg", taxCode : "LU-TVA"},
        {id:"LV", verboseName:"Latvia", taxCode: "LV-PVN"},
        {id:"MT", verboseName:"Malta", taxCode : "MT-Vat No"},
        {id:"NL", verboseName:"The Netherlands", taxCode:"NL-Btw-nr"},
        {id:"PL", verboseName:"Poland", taxCode:"PL-NIPC"},
        {id:"PT", verboseName:"Portugal", taxCode : "PT-NIF"},
        {id:"RO", verboseName:"Romania", taxCode:"RO-CIF"},
        {id:"SE", verboseName:"Sweden", taxCode :"SE-Momsnr"},
        {id:"SI", verboseName:"Slovenia", taxCode:"SI-ID za DDV"},
        {id:"SK", verboseName:"Slovakia", taxCode:"SK-IČ DPH"},
      ],
      taxCode : ["AU-UID", "BE-BTW-nr", "BG-ДДС номер", "CY-ΦΠΑ",
        "CZ-DIČ", "DE-USt-IdNr","DK-CVR","EE-KMKR" ,"EL-Arithmós Mētrṓou Fi-Pi-A", "ES-NIF" , "FI-ALV nro",
        "FR-TVA","GB-VAT Reg No","HR-PDV-ID OIB","HU-ANUM","IR-VAT","IT- Partita Iva" ,"LT-PVM kodas","LU-TVA",
        "LV-PVN","MT-Vat No", "NL-Btw-nr","PL-NIPC","PT-NIF","RO-CIF", "SE-Momsnr","SI-ID za DDV","SK-IČ DPH" ]
    }
  },
  beforeMount(){
    this.StartAnimation();
  },
  mounted () {
    window.prerenderReady = true;
  },
  computed: {
    betaState: function () {
      return process.env.VUE_APP_BETA_STATE;
    }
  },
  components: {

  },
  methods : {
    NextPossiton() {
      if(this.nextPosition === this.countries.length-1){
        this.nextPosition = 0;
      }
      else if (this.nextPosition > 0){
        this.nextPosition++;
      }
      else {
        this.nextPosition=1;
      }
    },
    Flag(countryCode) {
      return this.flagclass+GetISO.GetISoCodeNation(countryCode).toLowerCase();
    },
    identific(id1, id2){
      return `${id1}${id2}`
    },
    sleep(ms) {
    return new Promise(
          resolve => setTimeout(resolve, ms));
    },
    async StartAnimation (){
      this.linkMap = `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_API_GOGLE_KEY}&q=${encodeURIComponent("Louise Weiss building 1, Allée du Printemps, Strasbourg")}`;
      this.compne = `<iframe class="col-12"  height="400"  frameborder = "0" style = "border: 0" src="${this.linkMap}" allowfullscreen></iframe>`;

      for (let i= 0; i<= this.countries[this.nextPosition].taxCode.length-1; i++){
        this.textVatCode +=this.countries[this.nextPosition].taxCode[i];
        await this.sleep(900);
      }
      this.textVatCode = this.countries[this.nextPosition].taxCode;
      while (this.textVatCode.length >= 0){
        await this.sleep(600);
        this.textVatCode = this.textVatCode.slice(0,-1);
        await this.sleep(900);
        if(this.textVatCode.length === 0){
          this.NextPossiton();
          this.textVatCode = this.countries[this.nextPosition].taxCode;
        }
      }
    }
  }
}



</script>
<style>
  header {
    position: relative;
    height: 75vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }
  .VatcodeId {
    font-size: 4em;
    color: black;
    text-transform: uppercase;
  }
  .list-item, .flag-icon-item {
    display: inline-block;
    margin-right: 10px;
  }
  .bgFooter {
    background-color: #4D4D4D;
  }

  .lokk {
    font-size: 30rem;
  }
  .list-enter-active, .list-leave-active, .flag-icon-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ , .flag-icon-enter, .flag-icon-leave-to{
    opacity: 0;
    transform: translateY(30px);
  }

</style>
