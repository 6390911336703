<template>
  <div>
    <template v-if="louder">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="card shadow shadow-lg m-2">
            <div class="card-body">
              <template v-if="this.HaveInvoiceDate.haveInvoiceDate">
                <h5>Additional Billing Information</h5>
                <button class="btn btn-outline-primary mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#sendform"  aria-expanded="false" aria-controls="sendform" @click.prevent="view">View Informations</button>
              </template>
             <template v-if="this.HaveInvoiceDate.haveInvoiceDate">
                <template v-if="!modifystate">
                  <template v-if="!send">
                    <div  id="sendform" class="collapse">
                      <div class="row">
                        <div class="col-lg-6 m-1">
                          <div class="card">
                            <div class="card-header">
                              <h6>Check data</h6>
                            </div>
                            <div class="card-body">
                              <div class="col-12">
                                <div class="col-md-12">
                                  <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="isBusiness"
                                           v-model="invoiceUserDate.isBusiness">
                                    <label class="form-check-label" for="isBusiness">Are you a Business Company?</label>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="EmailUserIsMailInvoice"
                                           v-model="invoiceUserDate.EmailUserIsMailInvoice">
                                    <label class="form-check-label" for="EmailUserIsMailInvoice">Email for send invoice is equal your user
                                      Email?</label>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" id="haveEqualAdreesOfUser"
                                           v-model="invoiceUserDate.haveEqualAdreesOfUser">
                                    <label class="form-check-label" for="haveEqualAdreesOfUser">Address for invoice is equal your user
                                      Address?</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-5 m-1">
                          <div class="card">
                            <div class="card-header">
                              <h6>Last Update</h6>
                            </div>
                            <div class="card-body">
                              {{ new Date(invoiceUserDate.updatedAt).toDateString() }}

                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-header">
                              <h6>Name for Invoice</h6>
                            </div>
                            <div class="card-body">
                              <template v-if="invoiceUserDate.isBusiness">
                                {{ invoiceUserDate.companyName }}
                              </template>
                              <template v-if="!invoiceUserDate.isBusiness">
                                {{ invoiceUserDate.name }} {{ invoiceUserDate.surname}}
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 m-1">
                          <div class="card">
                            <div class="card-header">
                              <h6>Fiscal Code</h6>
                            </div>
                            <div class="card-body">
                              {{ invoiceUserDate.fiscalCode }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 m-1">
                          <div class="card">
                            <div class="card-header">
                              <h6>Phone Number</h6>
                            </div>
                            <div class="card-body">
                              {{ invoiceUserDate.phone }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 m-1">
                          <div class="card">
                            <div class="card-header">
                              <h6>Address</h6>
                            </div>
                            <div class="card-body">
                              <p>
                                {{ invoiceUserDate.street }}
                                {{ invoiceUserDate.zipCode }} {{ invoiceUserDate.city}} {{ invoiceUserDate.province}}
                                {{ invoiceUserDate.nation}}
                              </p>
                            </div>
                          </div>
                        </div>

                        <template v-if="invoiceUserDate.isBusiness">
                          <div class="col-lg-12 m-1">
                            <div class="card">
                              <div class="card-header">
                                <h6>Tax ID</h6>
                              </div>
                              <div class="card-body">
                                {{ invoiceUserDate.taxID }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 m-1">
                            <div class="card">
                              <div class="card-header">
                                <h6>E-Invoice Code for Billing</h6>
                              </div>
                              <div class="card-body">
                                {{ invoiceUserDate.eInvoiceCode }}
                              </div>
                            </div>
                          </div>
                          <template v-if="!invoiceUserDate.haveEqualAdreesOfUser">
                            <div class="col-lg-12 m-2">
                              <div class="card">
                                <div class="card-header">
                                  <h6>company Address</h6>
                                </div>
                                <div class="card-body">
                                  <p>
                                    {{ invoiceUserDate.companyStreet }}
                                    {{ invoiceUserDate.companyZipCode }} {{ invoiceUserDate.companyCity}} {{ invoiceUserDate.companyProvince}}
                                    {{ invoiceUserDate.companyNation}}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="!invoiceUserDate.EmailUserIsMailInvoice">
                            <div class="col-lg-12 m-2">
                              <div class="card">
                                <div class="card-header">
                                  <h6>Company Email for Invoice</h6>
                                </div>
                                <div class="card-body">
                                  <p>
                                    {{ invoiceUserDate.Emailforinvoice }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>
                  </template>
                </template>

                <br />
                <button class="btn btn-outline-primary" @click.prevent="onMody">Change Your Information for Invoice</button>
                <template v-if="modifystate">
                  <form class="row g-3">
                    <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="invoiceUserDate.isBusiness">
                        <label class="form-check-label" for="gridCheck">
                          Are you a Company?
                        </label>
                      </div>
                    </div>
                    <template v-if="invoiceUserDate.isBusiness">
                      <div class="col-md-6">
                        <label for="inputCompany" class="form-label">Company Name</label>
                        <input type="text" class="form-control" id="inputCompany" v-model="invoiceUserDate.companyName">
                      </div>
                      <div class="col-md-6">
                        <label for="inputTax" class="form-label">Tax ID (EU Format)</label>
                        <input type="text" class="form-control" id="inputTax" v-model="invoiceUserDate.taxID">
                      </div>
                      <div class="col-md-6">
                        <label for="inputFiscal" class="form-label">Fiscal Code</label>
                        <input type="text" class="form-control" id="inputFiscal" v-model="invoiceUserDate.fiscalCode">
                      </div>
                      <div class="col-md-6">
                        <label for="inputEinvoiceCode" class="form-label">EinvoiceCode (Optional)</label>
                        <input type="text" class="form-control" id="inputEinvoiceCode" v-model="invoiceUserDate.eInvoiceCode">
                      </div>
                    </template>
                    <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridEmailUserIsMailInvoice" v-model="invoiceUserDate.EmailUserIsMailInvoice">
                        <label class="form-check-label" for="gridEmailUserIsMailInvoice">
                          Invoice EMail is equal User Email?
                        </label>
                      </div>
                    </div>
                    <template v-if="!invoiceUserDate.EmailUserIsMailInvoice">
                      <div class="col-md-6">
                        <label for="inputInvMail" class="form-label">Invoice Email</label>
                        <input type="email" class="form-control" id="inputInvMail" v-model="invoiceUserDate.Emailforinvoice">
                      </div>
                    </template>
                    <template v-if="!invoiceUserDate.isBusiness">
                      <div class="col-md-6">
                        <label for="inputFiscal1" class="form-label">Fiscal Code</label>
                        <input type="text" class="form-control" id="inputFiscal1" v-model="invoiceUserDate.fiscalCode">
                      </div>
                    </template>
                    <div class="col-md-6">
                      <label for="inputPhone" class="form-label">Phone Number</label>
                      <input type="tel" class="form-control" id="inputPhone" v-model="invoiceUserDate.phone">
                    </div>
                    <div class="col-12">
                      <label for="inputAddress" class="form-label">Address</label>
                      <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" v-model="invoiceUserDate.street">
                    </div>
                    <div class="col-md-6">
                      <label for="inputAddress2" class="form-label">Zip Code</label>
                      <input type="text" class="form-control" id="inputAddress2" placeholder="36075" v-model="invoiceUserDate.zipCode">
                    </div>
                    <div class="col-md-6">
                      <label for="inputCity" class="form-label">City</label>
                      <input type="text" class="form-control" id="inputCity" v-model="invoiceUserDate.city">
                    </div>
                    <div class="col-6">
                      <label for="inputAddress4" class="form-label">Provice</label>
                      <input type="text" class="form-control" id="inputAddress4" placeholder="Veron" v-model="invoiceUserDate.province">
                    </div>
                    <div class="col-md-6">
                      <label for="inputState" class="form-label">State</label>
                      <select id="inputState" class="form-select" v-model="invoiceUserDate.nation">
                        <option selected>Choose...</option>
                        <option v-for="state in nation "> {{ state.code }}</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridhaveEqualAdreesOfUser" v-model="invoiceUserDate.haveEqualAdreesOfUser">
                        <label class="form-check-label" for="gridhaveEqualAdreesOfUser">
                          Invoice Address is equal User Address?
                        </label>
                      </div>
                    </div>
                    <template v-if="!invoiceUserDate.haveEqualAdreesOfUser">
                      <div class="col-12">
                        <label for="companyAddress" class="form-label">Company Address</label>
                        <input type="text" class="form-control" id="companyAddress" placeholder="1234 Main St" v-model="invoiceUserDate.companyStreet">
                      </div>
                      <div class="col-md-6">
                        <label for="inputcompanyAddress2" class="form-label">Company Zip Code</label>
                        <input type="text" class="form-control" id="inputcompanyAddress2" placeholder="36075" v-model="invoiceUserDate.companyZipCode">
                      </div>
                      <div class="col-md-6">
                        <label for="inputcompanyCity" class="form-label">Company City</label>
                        <input type="text" class="form-control" id="inputcompanyCity" v-model="invoiceUserDate.companyCity">
                      </div>
                      <div class="col-6">
                        <label for="inputcompanyAddress4" class="form-label">Company Provice</label>
                        <input type="text" class="form-control" id="inputcompanyAddress4" placeholder="Veron" v-model="invoiceUserDate.companyProvince">
                      </div>
                      <div class="col-md-6">
                        <label for="inputcompanyState" class="form-label">State</label>
                        <select id="inputcompanyState" class="form-select" v-model="invoiceUserDate.companyNation">
                          <option selected>Choose...</option>
                          <option v-for="state in nation "> {{ state.code }}</option>
                        </select>
                      </div>
                    </template>
                    <div class="col-12">
                      <button type="submit" class="btn btn-primary" @click.prevent="onUpd">Update</button>
                    </div>
                  </form>
                </template>
                <template v-if="send">
                  <div class="align-items-center pt-5">
                    <strong>sending...</strong>
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                  </div>
                </template>
              </template>
              <template v-if="!this.HaveInvoiceDate.haveInvoiceDate">
                <h4>Insert Your Information for Invoice</h4>
                <form class="row g-3">
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck" v-model="invoiceUserDate.isBusiness">
                      <label class="form-check-label" for="gridCheck">
                        Are you a Company?
                      </label>
                    </div>
                  </div>
                  <template v-if="invoiceUserDate.isBusiness">
                    <div class="col-md-6">
                      <label for="inputCompany" class="form-label">Company Name</label>
                      <input type="text" class="form-control" id="inputCompany" v-model="invoiceUserDate.companyName">
                    </div>
                    <div class="col-md-6">
                      <label for="inputTax" class="form-label">Tax ID (EU Format)</label>
                      <input type="text" class="form-control" id="inputTax" v-model="invoiceUserDate.taxID">
                    </div>
                    <div class="col-md-6">
                      <label for="inputFiscal" class="form-label">Fiscal Code</label>
                      <input type="text" class="form-control" id="inputFiscal" v-model="invoiceUserDate.fiscalCode">
                    </div>
                    <div class="col-md-6">
                      <label for="inputEinvoiceCode" class="form-label">EinvoiceCode (Optional)</label>
                      <input type="text" class="form-control" id="inputEinvoiceCode" v-model="invoiceUserDate.eInvoiceCode">
                    </div>
                  </template>
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridEmailUserIsMailInvoice" v-model="invoiceUserDate.EmailUserIsMailInvoice">
                      <label class="form-check-label" for="gridEmailUserIsMailInvoice">
                        Invoice EMail is equal User Email?
                      </label>
                    </div>
                  </div>
                  <template v-if="!invoiceUserDate.EmailUserIsMailInvoice">
                    <div class="col-md-6">
                      <label for="inputInvMail" class="form-label">Invoice Email</label>
                      <input type="email" class="form-control" id="inputInvMail" v-model="invoiceUserDate.Emailforinvoice">
                    </div>
                  </template>
                  <template v-if="!invoiceUserDate.isBusiness">
                    <div class="col-md-6">
                      <label for="inputFiscal1" class="form-label">Fiscal Code</label>
                      <input type="text" class="form-control" id="inputFiscal1" v-model="invoiceUserDate.fiscalCode">
                    </div>
                  </template>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label">Phone Number</label>
                    <input type="tel" class="form-control" id="inputPhone" v-model="invoiceUserDate.phone">
                  </div>
                  <div class="col-12">
                    <label for="inputAddress" class="form-label">Address</label>
                    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" v-model="invoiceUserDate.street">
                  </div>
                  <div class="col-md-6">
                    <label for="inputAddress2" class="form-label">Zip Code</label>
                    <input type="text" class="form-control" id="inputAddress2" placeholder="36075" v-model="invoiceUserDate.zipCode">
                  </div>
                  <div class="col-md-6">
                    <label for="inputCity" class="form-label">City</label>
                    <input type="text" class="form-control" id="inputCity" v-model="invoiceUserDate.city">
                  </div>
                  <div class="col-6">
                    <label for="inputAddress4" class="form-label">Provice</label>
                    <input type="text" class="form-control" id="inputAddress4" placeholder="Veron" v-model="invoiceUserDate.province">
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label">State</label>
                    <select id="inputState" class="form-select" v-model="invoiceUserDate.nation">
                      <option selected>Choose...</option>
                      <option v-for="state in nation "> {{ state.code }}</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridhaveEqualAdreesOfUser" v-model="invoiceUserDate.haveEqualAdreesOfUser">
                      <label class="form-check-label" for="gridhaveEqualAdreesOfUser">
                        Invoice Address is equal User Address?
                      </label>
                    </div>
                  </div>
                  <template v-if="!invoiceUserDate.haveEqualAdreesOfUser">
                    <div class="col-12">
                      <label for="companyAddress" class="form-label">Company Address</label>
                      <input type="text" class="form-control" id="companyAddress" placeholder="1234 Main St" v-model="invoiceUserDate.companyStreet">
                    </div>
                    <div class="col-md-6">
                      <label for="inputcompanyAddress2" class="form-label">Company Zip Code</label>
                      <input type="text" class="form-control" id="inputcompanyAddress2" placeholder="36075" v-model="invoiceUserDate.companyZipCode">
                    </div>
                    <div class="col-md-6">
                      <label for="inputcompanyCity" class="form-label">Company City</label>
                      <input type="text" class="form-control" id="inputcompanyCity" v-model="invoiceUserDate.companyCity">
                    </div>
                    <div class="col-6">
                      <label for="inputcompanyAddress4" class="form-label">Company Provice</label>
                      <input type="text" class="form-control" id="inputcompanyAddress4" placeholder="Veron" v-model="invoiceUserDate.companyProvince">
                    </div>
                    <div class="col-md-6">
                      <label for="inputcompanyState" class="form-label">State</label>
                      <select id="inputcompanyState" class="form-select" v-model="invoiceUserDate.companyNation">
                        <option selected>Choose...</option>
                        <option v-for="state in nation "> {{ state.code }}</option>
                      </select>
                    </div>
                  </template>
                  <div class="col-12">
                    <button type="submit" class="btn btn-primary" @click.prevent="onsub">Add Your Information for Invoice</button>
                  </div>
                </form>
                <br>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="!louder">
      <template v-if="!louder" class="p-2">
        <div class="align-items-center pt-5">
          <strong>Loading...</strong>
          <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as ApiService from '@/helpers/api.service'
export default {
  name: "invoiceDate",
  data () {
    return {
      haveData : true,
      louder : true,
      modifystate : false,
      send : false,
      error : false,
      billInfo : {
        nameCompany : null,
        vatNumber : null,
        fiscalCode : null,
        EInvoiceCode : null,
        address : null
      },
      invoiceUserDate : {
        id: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        nation: null,
        street: null,
        zipCode: null,
        city: null,
        province: null,
        OtherAddressData: null,
        isBusiness: false,
        companyName: null,
        fiscalCode: null,
        taxID: null,
        eInvoiceCode: null,
        haveEqualAdreesOfUser: true,
        companyNation: null,
        companyStreet: null,
        companyZipCode: null,
        companyCity: null,
        companyProvince: null,
        companyOtherAddressData: null,
        EmailUserIsMailInvoice: true,
        Emailforinvoice: null,
        YOU_REF_TEXT_ON_INVOICE: null,
        notes: null,
        active: true,
        createdBy: "VATID.CLOUD",
        portalID: this.$store.getters.user.id,
        OriginPortal: "SI",
        createdAt: "2022-01-29T16:35:44.000Z",
        updatedAt: "2022-02-21T18:55:17.000Z",
      },
      nation :  [
        {
          "id": 1,
          "name": "Andorra",
          "code": "AD",
          "isEuState": 0,
          "phone_code": 376,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 2,
          "name": "United Arab Emirates",
          "code": "AE",
          "isEuState": 0,
          "phone_code": 971,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 3,
          "name": "Afghanistan",
          "code": "AF",
          "isEuState": 0,
          "phone_code": 93,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 4,
          "name": "Antigua and Barbuda",
          "code": "AG",
          "isEuState": 0,
          "phone_code": 1268,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 5,
          "name": "Anguilla",
          "code": "AI",
          "isEuState": 0,
          "phone_code": 1264,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 6,
          "name": "Albania",
          "code": "AL",
          "isEuState": 0,
          "phone_code": 355,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 7,
          "name": "Armenia",
          "code": "AM",
          "isEuState": 0,
          "phone_code": 374,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 8,
          "name": "Angola",
          "code": "AO",
          "isEuState": 0,
          "phone_code": 244,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 9,
          "name": "Antarctica",
          "code": "AQ",
          "isEuState": 0,
          "phone_code": 672,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 10,
          "name": "Argentina",
          "code": "AR",
          "isEuState": 0,
          "phone_code": 54,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 11,
          "name": "American Samoa",
          "code": "AS",
          "isEuState": 0,
          "phone_code": 1684,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 12,
          "name": "Austria",
          "code": "AT",
          "isEuState": 1,
          "phone_code": 43,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 13,
          "name": "Australia",
          "code": "AU",
          "isEuState": 0,
          "phone_code": 61,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 14,
          "name": "Aruba",
          "code": "AW",
          "isEuState": 0,
          "phone_code": 297,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 15,
          "name": "Åland Islands",
          "code": "AX",
          "isEuState": 0,
          "phone_code": 358,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 16,
          "name": "Azerbaijan",
          "code": "AZ",
          "isEuState": 0,
          "phone_code": 994,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 17,
          "name": "Bosnia and Herzegovina",
          "code": "BA",
          "isEuState": 0,
          "phone_code": 387,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 18,
          "name": "Barbados",
          "code": "BB",
          "isEuState": 0,
          "phone_code": 1246,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 19,
          "name": "Bangladesh",
          "code": "BD",
          "isEuState": 0,
          "phone_code": 880,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 20,
          "name": "Belgium",
          "code": "BE",
          "isEuState": 1,
          "phone_code": 32,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 21,
          "name": "Burkina Faso",
          "code": "BF",
          "isEuState": 0,
          "phone_code": 226,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 22,
          "name": "Bulgaria",
          "code": "BG",
          "isEuState": 1,
          "phone_code": 359,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 23,
          "name": "Bahrain",
          "code": "BH",
          "isEuState": 0,
          "phone_code": 973,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 24,
          "name": "Burundi",
          "code": "BI",
          "isEuState": 0,
          "phone_code": 257,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 25,
          "name": "Benin",
          "code": "BJ",
          "isEuState": 0,
          "phone_code": 229,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 26,
          "name": "Saint Barthélémy",
          "code": "BL",
          "isEuState": 0,
          "phone_code": 590,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 27,
          "name": "Bermuda",
          "code": "BM",
          "isEuState": 0,
          "phone_code": 1441,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 28,
          "name": "Brunei Darussalam",
          "code": "BN",
          "isEuState": 0,
          "phone_code": 673,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 29,
          "name": "Bolivia",
          "code": "BO",
          "isEuState": 0,
          "phone_code": 591,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 30,
          "name": "Bonaire, Sint Eustatius and Saba",
          "code": "BQ",
          "isEuState": 0,
          "phone_code": 599,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 31,
          "name": "Brazil",
          "code": "BR",
          "isEuState": 0,
          "phone_code": 55,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 32,
          "name": "Bahamas",
          "code": "BS",
          "isEuState": 0,
          "phone_code": 1242,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 33,
          "name": "Bhutan",
          "code": "BT",
          "isEuState": 0,
          "phone_code": 975,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 34,
          "name": "Bouvet Island",
          "code": "BV",
          "isEuState": 0,
          "phone_code": 55,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 35,
          "name": "Botswana",
          "code": "BW",
          "isEuState": 0,
          "phone_code": 267,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 36,
          "name": "Belarus",
          "code": "BY",
          "isEuState": 0,
          "phone_code": 375,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 37,
          "name": "Belize",
          "code": "BZ",
          "isEuState": 0,
          "phone_code": 501,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 38,
          "name": "Canada",
          "code": "CA",
          "isEuState": 0,
          "phone_code": 1,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 39,
          "name": "Cocos (Keeling) Islands",
          "code": "CC",
          "isEuState": 0,
          "phone_code": 61,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 40,
          "name": "Central African Republic",
          "code": "CF",
          "isEuState": 0,
          "phone_code": 236,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 41,
          "name": "Democratic Republic of the Congo",
          "code": "CD",
          "isEuState": 0,
          "phone_code": 242,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 42,
          "name": "Congo",
          "code": "CG",
          "isEuState": 0,
          "phone_code": 243,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 43,
          "name": "Switzerland",
          "code": "CH",
          "isEuState": 0,
          "phone_code": 41,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 44,
          "name": "Côte d'Ivoire",
          "code": "CI",
          "isEuState": 0,
          "phone_code": 225,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 45,
          "name": "Cook Islands",
          "code": "CK",
          "isEuState": 0,
          "phone_code": 682,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 46,
          "name": "Chile",
          "code": "CL",
          "isEuState": 0,
          "phone_code": 56,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 47,
          "name": "Cameroon",
          "code": "CM",
          "isEuState": 0,
          "phone_code": 237,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 48,
          "name": "China",
          "code": "CN",
          "isEuState": 0,
          "phone_code": 86,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 49,
          "name": "Colombia",
          "code": "CO",
          "isEuState": 0,
          "phone_code": 57,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 50,
          "name": "Costa Rica",
          "code": "CR",
          "isEuState": 0,
          "phone_code": 506,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 51,
          "name": "Cuba",
          "code": "CU",
          "isEuState": 0,
          "phone_code": 53,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 52,
          "name": "Cape Verde",
          "code": "CV",
          "isEuState": 0,
          "phone_code": 238,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 53,
          "name": "Curaçao",
          "code": "CW",
          "isEuState": 0,
          "phone_code": 599,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 54,
          "name": "Christmas Island",
          "code": "CX",
          "isEuState": 0,
          "phone_code": 61,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 55,
          "name": "Cyprus",
          "code": "CY",
          "isEuState": 1,
          "phone_code": 357,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 56,
          "name": "Czech Republic",
          "code": "CZ",
          "isEuState": 1,
          "phone_code": 420,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 57,
          "name": "Germany",
          "code": "DE",
          "isEuState": 1,
          "phone_code": 49,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 58,
          "name": "Djibouti",
          "code": "DJ",
          "isEuState": 0,
          "phone_code": 253,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 59,
          "name": "Denmark",
          "code": "DK",
          "isEuState": 1,
          "phone_code": 45,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 60,
          "name": "Dominica",
          "code": "DM",
          "isEuState": 0,
          "phone_code": 1767,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 61,
          "name": "Dominican Republic",
          "code": "DO",
          "isEuState": 0,
          "phone_code": 1849,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 62,
          "name": "Algeria",
          "code": "DZ",
          "isEuState": 0,
          "phone_code": 213,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 63,
          "name": "Ecuador",
          "code": "EC",
          "isEuState": 0,
          "phone_code": 593,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 64,
          "name": "Estonia",
          "code": "EE",
          "isEuState": 1,
          "phone_code": 372,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 65,
          "name": "Egypt",
          "code": "EG",
          "isEuState": 0,
          "phone_code": 20,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 66,
          "name": "Western Sahara",
          "code": "EH",
          "isEuState": 0,
          "phone_code": 212,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 67,
          "name": "Eritrea",
          "code": "ER",
          "isEuState": 0,
          "phone_code": 291,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 68,
          "name": "Spain",
          "code": "ES",
          "isEuState": 1,
          "phone_code": 34,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 69,
          "name": "Ethiopia",
          "code": "ET",
          "isEuState": 0,
          "phone_code": 251,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 70,
          "name": "Finland",
          "code": "FI",
          "isEuState": 1,
          "phone_code": 358,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 71,
          "name": "Fiji",
          "code": "FJ",
          "isEuState": 0,
          "phone_code": 679,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 72,
          "name": "Falkland Islands",
          "code": "FK",
          "isEuState": 0,
          "phone_code": 500,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 73,
          "name": "Micronesia",
          "code": "FM",
          "isEuState": 0,
          "phone_code": 691,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 74,
          "name": "Faroe Islands",
          "code": "FO",
          "isEuState": 0,
          "phone_code": 298,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 75,
          "name": "France",
          "code": "FR",
          "isEuState": 1,
          "phone_code": 33,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 76,
          "name": "Gabon",
          "code": "GA",
          "isEuState": 0,
          "phone_code": 241,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 77,
          "name": "Grenada",
          "code": "GD",
          "isEuState": 0,
          "phone_code": 1473,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 78,
          "name": "Georgia",
          "code": "GE",
          "isEuState": 0,
          "phone_code": 995,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 79,
          "name": "French Guiana",
          "code": "GF",
          "isEuState": 0,
          "phone_code": 594,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 80,
          "name": "Ghana",
          "code": "GH",
          "isEuState": 0,
          "phone_code": 233,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 81,
          "name": "Gibraltar",
          "code": "GI",
          "isEuState": 0,
          "phone_code": 350,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 82,
          "name": "Guernsey",
          "code": "GG",
          "isEuState": 0,
          "phone_code": 44,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 83,
          "name": "Greenland",
          "code": "GL",
          "isEuState": 0,
          "phone_code": 299,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 84,
          "name": "Gambia",
          "code": "GM",
          "isEuState": 0,
          "phone_code": 220,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 85,
          "name": "Guinea",
          "code": "GN",
          "isEuState": 0,
          "phone_code": 224,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 86,
          "name": "Guadeloupe",
          "code": "GP",
          "isEuState": 0,
          "phone_code": 590,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 87,
          "name": "Equatorial Guinea",
          "code": "GQ",
          "isEuState": 0,
          "phone_code": 240,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 88,
          "name": "Greece",
          "code": "GR",
          "isEuState": 1,
          "phone_code": 30,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 89,
          "name": "South Georgia and the South Sandwich Islands",
          "code": "GS",
          "isEuState": 0,
          "phone_code": 500,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 90,
          "name": "Guatemala",
          "code": "GT",
          "isEuState": 0,
          "phone_code": 502,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 91,
          "name": "Guam",
          "code": "GU",
          "isEuState": 0,
          "phone_code": 1671,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 92,
          "name": "Guinea-Bissau",
          "code": "GW",
          "isEuState": 0,
          "phone_code": 245,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 93,
          "name": "Guyana",
          "code": "GY",
          "isEuState": 0,
          "phone_code": 592,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 94,
          "name": "Hong Kong",
          "code": "HK",
          "isEuState": 0,
          "phone_code": 852,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 95,
          "name": "Heard Island and McDonald Islands",
          "code": "HM",
          "isEuState": 0,
          "phone_code": 672,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 96,
          "name": "Honduras",
          "code": "HN",
          "isEuState": 0,
          "phone_code": 504,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 97,
          "name": "Croatia",
          "code": "HR",
          "isEuState": 1,
          "phone_code": 385,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 98,
          "name": "Haiti",
          "code": "HT",
          "isEuState": 0,
          "phone_code": 509,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 99,
          "name": "Hungary",
          "code": "HU",
          "isEuState": 1,
          "phone_code": 36,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 100,
          "name": "Indonesia",
          "code": "ID",
          "isEuState": 0,
          "phone_code": 62,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 101,
          "name": "Ireland",
          "code": "IE",
          "isEuState": 1,
          "phone_code": 353,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 102,
          "name": "Israel",
          "code": "IL",
          "isEuState": 0,
          "phone_code": 972,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 103,
          "name": "Isle of Man",
          "code": "IM",
          "isEuState": 0,
          "phone_code": 44,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 104,
          "name": "India",
          "code": "IN",
          "isEuState": 0,
          "phone_code": 91,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 105,
          "name": "British Indian Ocean Territory",
          "code": "IO",
          "isEuState": 0,
          "phone_code": 246,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 106,
          "name": "Iraq",
          "code": "IQ",
          "isEuState": 0,
          "phone_code": 964,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 107,
          "name": "Iran",
          "code": "IR",
          "isEuState": 0,
          "phone_code": 98,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 108,
          "name": "Iceland",
          "code": "IS",
          "isEuState": 0,
          "phone_code": 354,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 109,
          "name": "Italy",
          "code": "IT",
          "isEuState": 1,
          "phone_code": 39,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 110,
          "name": "Jersey",
          "code": "JE",
          "isEuState": 0,
          "phone_code": 44,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 111,
          "name": "Jamaica",
          "code": "JM",
          "isEuState": 0,
          "phone_code": 1876,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 112,
          "name": "Jordan",
          "code": "JO",
          "isEuState": 0,
          "phone_code": 962,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 113,
          "name": "Japan",
          "code": "JP",
          "isEuState": 0,
          "phone_code": 81,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 114,
          "name": "Kenya",
          "code": "KE",
          "isEuState": 0,
          "phone_code": 254,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 115,
          "name": "Kyrgyzstan",
          "code": "KG",
          "isEuState": 0,
          "phone_code": 996,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 116,
          "name": "Cambodia",
          "code": "KH",
          "isEuState": 0,
          "phone_code": 855,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 117,
          "name": "Kiribati",
          "code": "KI",
          "isEuState": 0,
          "phone_code": 686,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 118,
          "name": "Comoros",
          "code": "KM",
          "isEuState": 0,
          "phone_code": 269,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 119,
          "name": "Saint Kitts and Nevis",
          "code": "KN",
          "isEuState": 0,
          "phone_code": 1869,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 120,
          "name": "North Korea",
          "code": "KP",
          "isEuState": 0,
          "phone_code": 850,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 121,
          "name": "South Korea",
          "code": "KR",
          "isEuState": 0,
          "phone_code": 82,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 122,
          "name": "Kuwait",
          "code": "KW",
          "isEuState": 0,
          "phone_code": 965,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 123,
          "name": "Cayman Islands",
          "code": "KY",
          "isEuState": 0,
          "phone_code": 1345,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 124,
          "name": "Kazakhstan",
          "code": "KZ",
          "isEuState": 0,
          "phone_code": 7,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 125,
          "name": "Laos",
          "code": "LA",
          "isEuState": 0,
          "phone_code": 856,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 126,
          "name": "Lebanon",
          "code": "LB",
          "isEuState": 0,
          "phone_code": 961,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 127,
          "name": "Saint Lucia",
          "code": "LC",
          "isEuState": 0,
          "phone_code": 1758,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 128,
          "name": "Liechtenstein",
          "code": "LI",
          "isEuState": 0,
          "phone_code": 423,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 129,
          "name": "Sri Lanka",
          "code": "LK",
          "isEuState": 0,
          "phone_code": 94,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 130,
          "name": "Liberia",
          "code": "LR",
          "isEuState": 0,
          "phone_code": 231,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 131,
          "name": "Lesotho",
          "code": "LS",
          "isEuState": 0,
          "phone_code": 266,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 132,
          "name": "Lithuania",
          "code": "LT",
          "isEuState": 1,
          "phone_code": 370,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 133,
          "name": "Luxembourg",
          "code": "LU",
          "isEuState": 1,
          "phone_code": 352,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 134,
          "name": "Latvia",
          "code": "LV",
          "isEuState": 1,
          "phone_code": 371,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 135,
          "name": "Libya",
          "code": "LY",
          "isEuState": 0,
          "phone_code": 218,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 136,
          "name": "Morocco",
          "code": "MA",
          "isEuState": 0,
          "phone_code": 212,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 137,
          "name": "Monaco",
          "code": "MC",
          "isEuState": 0,
          "phone_code": 377,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 138,
          "name": "Moldova",
          "code": "MD",
          "isEuState": 0,
          "phone_code": 373,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 139,
          "name": "Montenegro",
          "code": "ME",
          "isEuState": 0,
          "phone_code": 382,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 140,
          "name": "Saint Martin (French part)",
          "code": "MF",
          "isEuState": 0,
          "phone_code": 590,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 141,
          "name": "Madagascar",
          "code": "MG",
          "isEuState": 0,
          "phone_code": 261,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 142,
          "name": "Marshall Islands",
          "code": "MH",
          "isEuState": 0,
          "phone_code": 692,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 143,
          "name": "North Macedonia",
          "code": "MK",
          "isEuState": 0,
          "phone_code": 389,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 144,
          "name": "Mali",
          "code": "ML",
          "isEuState": 0,
          "phone_code": 223,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 145,
          "name": "Myanmar",
          "code": "MM",
          "isEuState": 0,
          "phone_code": 95,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 146,
          "name": "Mongolia",
          "code": "MN",
          "isEuState": 0,
          "phone_code": 976,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 147,
          "name": "Macau",
          "code": "MO",
          "isEuState": 0,
          "phone_code": 853,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 148,
          "name": "Northern Mariana Islands",
          "code": "MP",
          "isEuState": 0,
          "phone_code": 1670,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 149,
          "name": "Martinique",
          "code": "MQ",
          "isEuState": 0,
          "phone_code": 596,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 150,
          "name": "Mauritania",
          "code": "MR",
          "isEuState": 0,
          "phone_code": 222,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 151,
          "name": "Montserrat",
          "code": "MS",
          "isEuState": 0,
          "phone_code": 1664,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 152,
          "name": "Malta",
          "code": "MT",
          "isEuState": 1,
          "phone_code": 356,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 153,
          "name": "Mauritius",
          "code": "MU",
          "isEuState": 0,
          "phone_code": 230,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 154,
          "name": "Maldives",
          "code": "MV",
          "isEuState": 0,
          "phone_code": 960,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 155,
          "name": "Malawi",
          "code": "MW",
          "isEuState": 0,
          "phone_code": 265,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 156,
          "name": "Mexico",
          "code": "MX",
          "isEuState": 0,
          "phone_code": 52,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 157,
          "name": "Malaysia",
          "code": "MY",
          "isEuState": 0,
          "phone_code": 60,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 158,
          "name": "Mozambique",
          "code": "MZ",
          "isEuState": 0,
          "phone_code": 258,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 159,
          "name": "Namibia",
          "code": "NA",
          "isEuState": 0,
          "phone_code": 264,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 160,
          "name": "New Caledonia",
          "code": "NC",
          "isEuState": 0,
          "phone_code": 687,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 161,
          "name": "Niger",
          "code": "NE",
          "isEuState": 0,
          "phone_code": 227,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 162,
          "name": "Norfolk Island",
          "code": "NF",
          "isEuState": 0,
          "phone_code": 672,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 163,
          "name": "Nigeria",
          "code": "NG",
          "isEuState": 0,
          "phone_code": 234,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 164,
          "name": "Nicaragua",
          "code": "NI",
          "isEuState": 0,
          "phone_code": 505,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 165,
          "name": "Netherlands",
          "code": "NL",
          "isEuState": 1,
          "phone_code": 31,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 166,
          "name": "Norway",
          "code": "NO",
          "isEuState": 0,
          "phone_code": 47,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 167,
          "name": "Nepal",
          "code": "NP",
          "isEuState": 0,
          "phone_code": 977,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 168,
          "name": "Nauru",
          "code": "NR",
          "isEuState": 0,
          "phone_code": 674,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 169,
          "name": "Niue",
          "code": "NU",
          "isEuState": 0,
          "phone_code": 683,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 170,
          "name": "New Zealand",
          "code": "NZ",
          "isEuState": 0,
          "phone_code": 64,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 171,
          "name": "Oman",
          "code": "OM",
          "isEuState": 0,
          "phone_code": 968,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 172,
          "name": "Panama",
          "code": "PA",
          "isEuState": 0,
          "phone_code": 507,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 173,
          "name": "Peru",
          "code": "PE",
          "isEuState": 0,
          "phone_code": 51,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 174,
          "name": "French Polynesia",
          "code": "PF",
          "isEuState": 0,
          "phone_code": 689,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 175,
          "name": "Papua New Guinea",
          "code": "PG",
          "isEuState": 0,
          "phone_code": 675,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 176,
          "name": "Philippines",
          "code": "PH",
          "isEuState": 0,
          "phone_code": 63,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 177,
          "name": "Pakistan",
          "code": "PK",
          "isEuState": 0,
          "phone_code": 92,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 178,
          "name": "Poland",
          "code": "PL",
          "isEuState": 1,
          "phone_code": 48,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 179,
          "name": "Saint Pierre and Miquelon",
          "code": "PM",
          "isEuState": 0,
          "phone_code": 508,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 180,
          "name": "Pitcairn Islands",
          "code": "PN",
          "isEuState": 0,
          "phone_code": 64,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 181,
          "name": "Puerto Rico",
          "code": "PR",
          "isEuState": 0,
          "phone_code": 1939,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 182,
          "name": "State of Palestine",
          "code": "PS",
          "isEuState": 0,
          "phone_code": 970,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 183,
          "name": "Portugal",
          "code": "PT",
          "isEuState": 1,
          "phone_code": 351,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 184,
          "name": "Palau",
          "code": "PW",
          "isEuState": 0,
          "phone_code": 680,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 185,
          "name": "Paraguay",
          "code": "PY",
          "isEuState": 0,
          "phone_code": 595,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 186,
          "name": "Qatar",
          "code": "QA",
          "isEuState": 0,
          "phone_code": 974,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 187,
          "name": "Réunion",
          "code": "RE",
          "isEuState": 0,
          "phone_code": 262,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 188,
          "name": "Romania",
          "code": "RO",
          "isEuState": 1,
          "phone_code": 40,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 189,
          "name": "Serbia",
          "code": "RS",
          "isEuState": 0,
          "phone_code": 381,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 190,
          "name": "Russian Federation",
          "code": "RU",
          "isEuState": 0,
          "phone_code": 7,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 191,
          "name": "Rwanda",
          "code": "RW",
          "isEuState": 0,
          "phone_code": 250,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 192,
          "name": "Saudi Arabia",
          "code": "SA",
          "isEuState": 0,
          "phone_code": 966,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 193,
          "name": "Solomon Islands",
          "code": "SB",
          "isEuState": 0,
          "phone_code": 677,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 194,
          "name": "Seychelles",
          "code": "SC",
          "isEuState": 0,
          "phone_code": 248,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 195,
          "name": "Sudan",
          "code": "SD",
          "isEuState": 0,
          "phone_code": 249,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 196,
          "name": "Sweden",
          "code": "SE",
          "isEuState": 1,
          "phone_code": 46,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 197,
          "name": "Singapore",
          "code": "SG",
          "isEuState": 0,
          "phone_code": 65,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 198,
          "name": "Saint Helena, Ascension and Tristan da Cunha",
          "code": "SH",
          "isEuState": 0,
          "phone_code": 290,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 199,
          "name": "Slovenia",
          "code": "SI",
          "isEuState": 1,
          "phone_code": 386,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 200,
          "name": "Svalbard and Jan Mayen",
          "code": "SJ",
          "isEuState": 0,
          "phone_code": 47,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 201,
          "name": "Slovakia",
          "code": "SK",
          "isEuState": 1,
          "phone_code": 421,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 202,
          "name": "Sierra Leone",
          "code": "SL",
          "isEuState": 0,
          "phone_code": 232,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 203,
          "name": "San Marino",
          "code": "SM",
          "isEuState": 0,
          "phone_code": 378,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 204,
          "name": "Senegal",
          "code": "SN",
          "isEuState": 0,
          "phone_code": 221,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 205,
          "name": "Somalia",
          "code": "SO",
          "isEuState": 0,
          "phone_code": 252,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 206,
          "name": "Suriname",
          "code": "SR",
          "isEuState": 0,
          "phone_code": 597,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 207,
          "name": "South Sudan",
          "code": "SS",
          "isEuState": 0,
          "phone_code": 211,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 208,
          "name": "São Tomé and Príncipe",
          "code": "ST",
          "isEuState": 0,
          "phone_code": 239,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 209,
          "name": "El Salvador",
          "code": "SV",
          "isEuState": 0,
          "phone_code": 503,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 210,
          "name": "Sint Maarten (Dutch part)",
          "code": "SX",
          "isEuState": 0,
          "phone_code": 1721,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 211,
          "name": "Syria",
          "code": "SY",
          "isEuState": 0,
          "phone_code": 963,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 212,
          "name": "Swaziland",
          "code": "SZ",
          "isEuState": 0,
          "phone_code": 268,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 213,
          "name": "Turks and Caicos Islands",
          "code": "TC",
          "isEuState": 0,
          "phone_code": 1649,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 214,
          "name": "Chad",
          "code": "TD",
          "isEuState": 0,
          "phone_code": 235,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 215,
          "name": "French Southern Territories",
          "code": "TF",
          "isEuState": 0,
          "phone_code": 262,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 216,
          "name": "Togo",
          "code": "TG",
          "isEuState": 0,
          "phone_code": 228,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 217,
          "name": "Thailand",
          "code": "TH",
          "isEuState": 0,
          "phone_code": 66,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 218,
          "name": "Tajikistan",
          "code": "TJ",
          "isEuState": 0,
          "phone_code": 992,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 219,
          "name": "Tokelau",
          "code": "TK",
          "isEuState": 0,
          "phone_code": 690,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 220,
          "name": "Turkmenistan",
          "code": "TM",
          "isEuState": 0,
          "phone_code": 993,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 221,
          "name": "Tunisia",
          "code": "TN",
          "isEuState": 0,
          "phone_code": 216,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 222,
          "name": "Tonga",
          "code": "TO",
          "isEuState": 0,
          "phone_code": 676,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 223,
          "name": "Timor-Leste",
          "code": "TL",
          "isEuState": 0,
          "phone_code": 670,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 224,
          "name": "Turkey",
          "code": "TR",
          "isEuState": 0,
          "phone_code": 90,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 225,
          "name": "Trinidad and Tobago",
          "code": "TT",
          "isEuState": 0,
          "phone_code": 1868,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 226,
          "name": "Tuvalu",
          "code": "TV",
          "isEuState": 0,
          "phone_code": 688,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 227,
          "name": "Taiwan",
          "code": "TW",
          "isEuState": 0,
          "phone_code": 886,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 228,
          "name": "Tanzania",
          "code": "TZ",
          "isEuState": 0,
          "phone_code": 255,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 229,
          "name": "Ukraine",
          "code": "UA",
          "isEuState": 0,
          "phone_code": 380,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 230,
          "name": "Uganda",
          "code": "UG",
          "isEuState": 0,
          "phone_code": 256,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 231,
          "name": "United Kingdom",
          "code": "GB",
          "isEuState": 0,
          "phone_code": 44,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 232,
          "name": "USA Minor Outlying Islands",
          "code": "UM",
          "isEuState": 0,
          "phone_code": 699,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 233,
          "name": "United States",
          "code": "US",
          "isEuState": 0,
          "phone_code": 1,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 234,
          "name": "Uruguay",
          "code": "UY",
          "isEuState": 0,
          "phone_code": 598,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 235,
          "name": "Uzbekistan",
          "code": "UZ",
          "isEuState": 0,
          "phone_code": 998,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 236,
          "name": "Holy See (Vatican City State)",
          "code": "VA",
          "isEuState": 0,
          "phone_code": 379,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 237,
          "name": "Saint Vincent and the Grenadines",
          "code": "VC",
          "isEuState": 0,
          "phone_code": 1784,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 238,
          "name": "Venezuela",
          "code": "VE",
          "isEuState": 0,
          "phone_code": 58,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 239,
          "name": "Virgin Islands (British)",
          "code": "VG",
          "isEuState": 0,
          "phone_code": 1284,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 240,
          "name": "Virgin Islands (USA)",
          "code": "VI",
          "isEuState": 0,
          "phone_code": 1340,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 241,
          "name": "Vietnam",
          "code": "VN",
          "isEuState": 0,
          "phone_code": 84,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 242,
          "name": "Vanuatu",
          "code": "VU",
          "isEuState": 0,
          "phone_code": 678,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 243,
          "name": "Wallis and Futuna",
          "code": "WF",
          "isEuState": 0,
          "phone_code": 681,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 244,
          "name": "Samoa",
          "code": "WS",
          "isEuState": 0,
          "phone_code": 685,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 245,
          "name": "Yemen",
          "code": "YE",
          "isEuState": 0,
          "phone_code": 967,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 246,
          "name": "Mayotte",
          "code": "YT",
          "isEuState": 0,
          "phone_code": 262,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 247,
          "name": "South Africa",
          "code": "ZA",
          "isEuState": 0,
          "phone_code": 27,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 248,
          "name": "Zambia",
          "code": "ZM",
          "isEuState": 0,
          "phone_code": 260,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 249,
          "name": "Zimbabwe",
          "code": "ZW",
          "isEuState": 0,
          "phone_code": 263,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        },
        {
          "id": 250,
          "name": "Kosovo",
          "code": "XK",
          "isEuState": 0,
          "phone_code": 383,
          "createdAt": "0000-00-00 00:00:00",
          "updatedAt": "0000-00-00 00:00:00"
        }
      ]

    }
  },
  beforeMount() {
    this.Load();
  },
  computed: {
    ...mapGetters({
      InvoiceDate : 'InvoiceDate',
    })
  },
  methods : {
    ...mapActions({
      getInvoiceDate : 'getInvoiceDate',
      postInvoiceDate : 'postInvoiceDate',
      postUpdInvoiceDate : 'postUpdInvoiceDate'
    }),
    Load () {
        this.getInvoiceDate();
       this.view()
        this.louder = true;

    },
    view() {
      this.invoiceUserDate = this.InvoiceDate.invoiceDate
    },
    onsub(){
      if(this.invoiceUserDate.city === null || this.invoiceUserDate.city === '' ){
        this.error = true
      }
      this.postInvoiceDate(this.invoiceUserDate);
    },
    onMody(){
      this.modifystate = !this.modifystate;
      //this.billInfo = JSON.parse(JSON.stringify(this.Credits.credits.billingInfo));
    },
    onUpd() {
      this.postUpdInvoiceDate(this.invoiceUserDate);
    }
  }
}
</script>

<style scoped>

</style>
