<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary sticky-top">
      <div class="container">
        <a class="navbar-brand text-bg-primary" href="/">VatID</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li>
          </ul>
          <!-- content on finish nav bar -->
          <form class="d-flex" role="auth">
            <template  v-if="auth">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 m-2">
                <li class="nav-item dropdown me-2">
                  <p class="dropdown-toggle text-bg-primary" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fad fa-radar"></i> Search
                  </p>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link to="/search" custom v-slot="{ navigate }" class="dropdown-item">
                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-address-card"></i> By Name Company</button>
                      </router-link>
                    </li>
                    <li>
                      <router-link  to="/searchByVat" custom v-slot="{ navigate }" class="dropdown-item">
                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-barcode-read"></i> By Vat Number Code</button>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <p class="dropdown-toggle text-bg-primary" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fad fa-user"></i> {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }}
                  </p>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link to="/user" custom v-slot="{ navigate }" class="dropdown-item">
                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-house-user"></i> My User</button>
                      </router-link>
                    </li>
                    <li>
                      <router-link  to="/history" custom v-slot="{ navigate }" class="dropdown-item">
                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-history"></i> History</button>
                      </router-link>
                    </li>
<!--                    <li>-->
<!--                      <router-link  to="/credit" custom v-slot="{ navigate }" class="dropdown-item">-->
<!--                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-credit-card"></i> Credit</button>-->
<!--                      </router-link>-->
<!--                    </li>-->
                    <li>
                      <router-link  to="/fee" custom v-slot="{ navigate }" class="dropdown-item">
                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-charging-station"></i> Fee</button>
                      </router-link>
                    </li>
                    <li>
                      <router-link  to="/ticket" custom v-slot="{ navigate }" class="dropdown-item">
                        <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-headset"></i> Support</button>
                      </router-link>
                    </li>
                    <template v-if="admin" >
                      <li>
                        <router-link  to="/admin" custom v-slot="{ navigate }" class="dropdown-item">
                          <button @click="navigate" @keypress.enter="navigate" class="" type="submit"><i class="fad fa-user-crown"></i> Admin</button>
                        </router-link>
                      </li>
                    </template>
                    <li><hr  class="dropdown-divider"></li>
                    <li>
                    <button v-if="auth" class="dropdown-item" @click.prevent="BtnLogout"><i class="fad fa-sign-out"></i> Logout</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
            <router-link  v-if="!auth" to="/login" custom v-slot="{ navigate }" class="m-2">
              <button @click="navigate" @keypress.enter="navigate" class="btn btn-light ml-1 btn-lg" type="submit">Login</button>
            </router-link>
            <router-link  v-if="!auth" to="/register" custom v-slot="{ navigate }" class="m-2">
              <button @click="navigate" @keypress.enter="navigate" class="btn btn-light ml-1 btn-lg" type="submit">Register</button>
            </router-link>
          </form>
        </div>
      </div>
    </nav>
    <router-view/>
    <br />
    <notifications position="bottom right" />
    <footer class="footer fixed-bottom bg-secondary " v-if="betaState">
      <div class="container">
        <span class="text-white"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Attection is a Beta for Tester © 2022 VatID.Cloud By Alessio Pellizzaro, Italy, European Union</span></span></span>
      </div>
    </footer>
  </div>
</template>


<script>
  import * as ApiSerivice from '../src/helpers/api.service'
  import { mapActions } from 'vuex'
  ApiSerivice.init();
  export default {
    name: 'App',
    data () {
      return {
        classNav: 'navbar navbar-expand-lg navbar-dark bg-primary'
      }
    },
    methods : {
      ...mapActions({
        logout: 'logout',
      }),
      BtnLogout(){
        this.logout();
      }
    },
    computed : {
      auth() {
        return this.$store.getters.isAuth;
      },
      admin() {
        return this.$store.getters.isAdmin.IsAd;
      },
      classNave(){
        return !this.$store.getters.isAdmin.IsAd? this.classNav : 'navbar navbar-expand-lg navbar-light bg-secondary';
      },
      betaState: function () {
          return process.env.VUE_APP_BETA_STATE === "true";
      }

    },

  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100&display=swap');
#app {
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

  .fad {
    color: cornflowerblue;
  }
  .fa-home-lg {
    color: #e3e3e3;
  }
  .fa-user {
    color: #e3e3e3;
  }
  .fa-info-square {
    color: #e3e3e3;
  }
  .fa-radar {
    color: #e3e3e3;
  }
  .textNav {
    color: #e3e3e3!important;
  }
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
