import Vue from 'vue'
//import { sleep } from '@/lib/util'
//import { postJsonUno, postJsonDue, postJsonTre } from '@/jsonresponse'

const state = {
    companys: []
}
const getters = {
    getCompanys (state) {
        return state.companys
    },
    getCompany: (state, getters) => (paramkey) => {
        if (getters.getCompanys) {
            const funf = getters.getCompanys.find(el => { return el.key === paramkey })
            if (funf) {
                return funf
            } else {
                return null
            }
        }
        return null
    }
}
const actions = {
    fetchCompany (contex, payload) {

    }
}
const mutations = {
    setCompanys (state, payload) {
        state.users = payload
    },
    setCompany (state, payload) {
        Vue.set(state.user, payload.key, payload)
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
