import Vue from "vue";
import axios from "axios";
import VueAxios from 'vue-axios';
import store from '../store'
Vue.use(VueAxios, axios);

export function setHeder() {
    Vue.axios.defaults.baseURL= process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.get['Accepts']= 'application/json';
    const ID_TOKEN_KEY = process.env.VUE_APP_ID_TOKEN;
    let getToken = store.getters.token;
    if(getToken){
        Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    }


}

export function init() {
    setHeder();
    Vue.axios.interceptors.request.use(function (config) {
        config.params={
        };
        return config;
    }, function (error) {
            return Promise.reject(error);
    });
    Vue.axios.interceptors.response.use(function (config) {
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
}

export function query(resource, params) {
    return Vue.axios.get(`${resource}`, params);
}

export function get(resource) {
    return Vue.axios.get(`${resource}`);
}
export function post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
}

export function update(resource, params) {
    return Vue.axios.put(`${resource}`, params);
}

export function del(resource) {
    return Vue.axios.delete(`${resource}`);
}

