<template>
 <div class="container">
   <div class="row text-center mt-5">
     <h1 class="heading-title">User Invoice Information</h1>
   </div>
   <br/>
   <div class="row">
     <div class="col-md-12 col-lg-12">
       <h4 class="mb-3">Billing address</h4>
       <form class="needs-validation" novalidate="">
         <div class="row g-3">
           <div class="col-12">
             <h5>{{ this.$store.state.user.name }} {{ this.$store.state.user.surname }}</h5>
             <h6>{{ this.$store.state.user.email }}</h6>
           </div>
           <div class="col-7">
             <label for="address" class="form-label">Address</label>
             <input type="text" class="form-control" id="address" placeholder="1234 Main St" required="" v-model="user.billing.street">
             <div class="invalid-feedback">
               Please enter your shipping address.
             </div>
           </div>

           <div class="col-5">
             <label for="address2" class="form-label">City</label>
             <input type="text" class="form-control" id="address2" placeholder="Strasbourg" v-model="user.billing.city">
           </div>
           <div class="col-md-5">
             <label for="country" class="form-label">Country</label>
             <select class="form-select" id="country" required="" v-model="user.billing.nation">
               <option value="">Choose...</option>
                 <option>Albania</option>
                 <option>Andorra</option>
                 <option>Armenia</option>
                 <option>Austria</option>
                 <option>Azerbaijan</option>
                 <option>Belarus</option>
                 <option>Belgium</option>
                 <option>Bosnia and Herzegovina</option>
                 <option>Bulgaria</option>
                 <option>Croatia</option>
                 <option>Cyprus</option>
                 <option>Czech Republic</option>
                 <option>Denmark</option>
                 <option>Estonia</option>
                 <option>Finland</option>
                 <option>France</option>
                 <option>Georgia</option>
                 <option>Germany</option>
                 <option>Greece</option>
                 <option>Hungary</option>
                 <option>Iceland</option>
                 <option>Ireland</option>
                 <option>Italy</option>
                 <option>Kazakhstan</option>
                 <option>Kosovo</option>
                 <option>Latvia</option>
                 <option>Liechtenstein</option>
                 <option>Lithuania</option>
                 <option>Luxembourg</option>
                 <option>Malta</option>
                 <option>Moldova</option>
                 <option>Monaco</option>
                 <option>Montenegro</option>
                 <option>Netherlands</option>
                 <option>North Macedonia</option>
                 <option>Norway</option>
                 <option>Poland</option>
                 <option>Portugal</option>
                 <option>Romania</option>
                 <option>Russia</option>
                 <option>San Marino</option>
                 <option>Serbia</option>
                 <option>Slovakia</option>
                 <option>Slovenia</option>
                 <option>Spain</option>
                 <option>Sweden</option>
                 <option>Switzerland</option>
                 <option>Turkey</option>
                 <option>Ukraine</option>
                 <option>United Kingdom</option>
                 <option>Vatican City</option>
             </select>
             <div class="invalid-feedback">
               Please select a valid country.
             </div>
           </div>
           <div class="col-md-4">
             <label for="state" class="form-label">State</label>
             <input type="text" class="form-control" id="state" placeholder="Vicenza" required="" v-model="user.billing.province">
             <div class="invalid-feedback">
               Please provide a valid state.
             </div>
           </div>
           <div class="col-md-3">
             <label for="zip" class="form-label">Zip</label>
             <input type="text" class="form-control" id="zip" placeholder="" required="" v-model="user.billing.zipCode">
             <div class="invalid-feedback">
               Zip code required.
             </div>
           </div>
         </div>
         <hr class="my-4">
         <input type="checkbox" class="form-check-input" id="isBusiness" v-model="user.billing.isBusiness">
         <label class="form-check-label" for="isBusiness">Billings to a Business Company?</label>

         <br />
         <br />
          <template v-if="user.billing.isBusiness">
            <div class="row g-3">
              <div class="col-12">
                  <h6>Add Business Company Informations</h6>
              </div>
              <div class="col-12">
                <label for="companyName" class="form-label">Company Name</label>
                <input type="text" class="form-control" id="companyName" placeholder="MyCompany Se" required="" v-model="user.billing.companyName">
                <div class="invalid-feedback">
                  Please enter your Company Name.
                </div>
              </div>
              <div class="col-4">
                <label for="taxID" class="form-label">TaxID</label>
                <input type="text" class="form-control" id="taxID" placeholder="EU0X00000" v-model="user.billing.taxID">
              </div>
              <div class="col-4">
                <label for="FiscalCode" class="form-label">FiscalCode</label>
                <input type="text" class="form-control" id="FiscalCode" placeholder="00000000" v-model="user.billing.fiscalCode">
              </div>
              <div class="col-4">
                <label for="Invoice" class="form-label">E-Invoice Code</label>
                <input type="text" class="form-control" id="Invoice" placeholder="0000000" v-model="user.billing.eInvoiceCode">
              </div>
            </div>
          </template>
         <hr class="my-4">
         <button class="w-100 btn btn-primary btn-lg" type="submit" @click.prevent="sub">Send Data</button>
       </form>
     </div>
   </div>
 </div>
</template>

<script>
import * as ApiService from '@/helpers/api.service'
export default {
  name: "UserInfoForm",
  components: {},
  data (){
      return {
        user :{},
        isBusiness : false
      }
  },
  beforeMount() {
    this.user = this.$store.state.user;
  },
  methods : {
   async sub() {
      let url = `/auth/user/info/billing`;
      await ApiService.post(url, { ...this.user.billing }).then((res) => {
        this.$router.push('/user');
      }).catch((err) =>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
