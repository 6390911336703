<template>
    <main>
        <template v-if="!louder" class="container p-2">
            <div class="align-items-center pt-5">
                <strong>Loading...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder">
            <div class="table-responsive-md">
                <table class="table table-hover">
                    <thead class="table-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">countryCode</th>
                        <th scope="col">ViewCompany</th>
                        <th scope="col">View</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(el, index) in ListCompany" :key="index">
                        <th scope="row">{{ index+1 }}</th>
                        <td>{{ el.name }}</td>
                        <td>{{ el.countryCode}}</td>
                        <td>{{ el.ViewCompany}}</td>
                        <td><router-link tag="a" class="btn btn-outline-dark"  :to="`/company/${el.ComID}`">View</router-link></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: "CompanyClassification",
        data() {
            return {
                louder : false
            }
        },
        beforeMount(){
          this.Load();
        },
        computed: {
            ...mapGetters({
                getListCompanyAdmin: 'Admin/getListCompanyAdmin',
            }),
            ListCompany : function () {
                return this.getListCompanyAdmin;
            }
        },
        methods : {
            ...mapActions({
                fetchCompanyClassf : 'Admin/fetchCompanyClassf'
            }),
            Load () {
                this.fetchCompanyClassf().then(() => { this.louder = true });
            }
        }
    }
</script>

<style scoped>

</style>