<template>
    <div class="container">
        <div class="row mt-5">
            <div class="col-sm-12">
                <h2 class="text-center text-info">Ops..</h2>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-12">
                <h2 class="text-center text-info">You Not Have Subscriptions</h2>
            </div>
        </div>
        <div class="row">
            <div class="center text-center col-sm-12">
                <h1 class="center text-danger display-1"><i class="fad fa-do-not-enter display-1 text-danger"></i> 401 <i class="fad fa-do-not-enter display-1 text-danger"></i></h1>
            </div>
        </div>
        <router-link tag="a" to="/Fee" class="btn btn-primary btn-lg">View Available Fee</router-link>
    </div>
</template>


<script>
    export default {
        name: "NotFound",
        beforeMount() {
          this.$notify({
            title: 'Not Credit',
            type : 'error',
            duration: 10000,
            speed: 1000,
            text: `Hi ${this.$store.getters.user.name}!\n <br> You Not Have Subscriptions`
          });
        }
    }
</script>

<style scoped>

</style>
