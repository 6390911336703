<template>
  <main class="container p-2">
    <div class="row">
      <div class="col-sm-11">
        <div class="card ">
          <div class="card-body bg-primary shadow rounded" >
            <h5 class="card-title display-1 text-white">VatID</h5>
            <p class="card-text display-3 text-white">Get TaxID Company on Europe</p>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-11">
        <div class="card">
          <div class="card-body shadow rounded" >
            <h5 class="card-title"> <i class="fad fa-info-square" style="color: cornflowerblue;"></i> What is it VatID platform <i class="fad fa-question"></i> </h5>
            <p class="card-text">The VatID Platform is a portal where it is possible to retrieve the public information of companies established in one of the states of the European Union in a simple way without having to spend hours</p>
            <h5 class="card-title"><i class="fad fa-id-badge"></i> Why VatID <i class="fad fa-question"></i> </h5>
            <p class="card-text"> VatID because we create an association between the name often known co her unique national ID </p>
            <p class="card-text"> <i class="fad fa-equals"></i> VatID is a Unique National ID <i class="fad fa-at"></i> </p>
            <h5 class="card-title"><i class="fad fa-landmark-alt"></i> Why all this <i class="fad fa-question"></i> </h5>
            <p class="card-text"> We encourage market transparency towards the final consumer <i class="fad fa-user-astronaut"></i> , even if the latest regulations require the identification of oneself as a legal person in their communication channels, the VatID is still too often the only means that the consumer has to retrieve information. on the fingerprint and ensure knowing who you are dealing with is not declared or hidden under Web pages not easily accessible, allowing even in cases of "Bad Company" <i class="fad fa-user-secret"></i> regarding the risks of reporting to the authorities </p>
            <h5 class="card-title"><i class="fad fa-id-badge"></i> How do we get the data <i class="fad fa-question"></i> </h5>
            <p class="card-text">We do what you would do, we manually search for any information in the world of the Web in compliance with the regulations in force in the European Union and we use the means already made available by the European Union and member states to validate the information</p>
            <p class="card-text"> <i class="fad fa-equals"></i> VatID is a Unique National ID <i class="fad fa-at"></i> </p>
            <h5 class="card-title"><i class="fad fa-credit-card"></i>Why Paid <i class="fad fa-question"></i> </h5>
            <p class="card-text"> because to maintain this universe of information our operators lose months of their existence to support us and sustain the transparency of the market, thanks to your spending of a few cents (less than 60 cents) for each of our Credit, you help us to keep this goal alive for tomorrow </p>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row ">
      <div class="col-sm-11">
        <div class="card">
          <div class="card-body shadow rounded d-flex" >
            <div class="col-lg-3">
              <div class="list-group" id="list-tab" role="tablist">
                <a class="list-group-item list-group-item-action active" id="list-AU-list" data-bs-toggle="list" href="#list-au" role="tab" aria-controls="Austria">AU-UID</a>
                <a class="list-group-item list-group-item-action" id="list-BE-list" data-bs-toggle="list" href="#list-be" role="tab" aria-controls="Belgio">BE-BTW-nr</a>
                <a class="list-group-item list-group-item-action" id="list-BG-list" data-bs-toggle="list" href="#list-bg" role="tab" aria-controls="Bulgaria">BG-ДДС номер</a>
                <a class="list-group-item list-group-item-action" id="list-HR-list" data-bs-toggle="list" href="#list-hr" role="tab" aria-controls="Croazia">HR-PDV-ID OIB</a>
                <a class="list-group-item list-group-item-action" id="list-CY-list" data-bs-toggle="list" href="#list-cy" role="tab" aria-controls="Cipro">CY-ΦΠΑ</a>
                <a class="list-group-item list-group-item-action" id="list-CZ-list" data-bs-toggle="list" href="#list-cz" role="tab" aria-controls="Ceca">CZ-DIČ</a>
                <a class="list-group-item list-group-item-action" id="list-DK-list" data-bs-toggle="list" href="#list-dk" role="tab" aria-controls="Danimarca">DK-CVR</a>
                <a class="list-group-item list-group-item-action" id="list-EE-list" data-bs-toggle="list" href="#list-ee" role="tab" aria-controls="Estonia">EE-KMKR</a>
                <a class="list-group-item list-group-item-action" id="list-FI-list" data-bs-toggle="list" href="#list-fi" role="tab" aria-controls="Filandia">FI-ALV nro</a>
                <a class="list-group-item list-group-item-action" id="list-FR-list" data-bs-toggle="list" href="#list-fr" role="tab" aria-controls="Francia">FR-TVA</a>
                <a class="list-group-item list-group-item-action" id="list-DE-list" data-bs-toggle="list" href="#list-de" role="tab" aria-controls="Germania">DE-USt-IdNr</a>
                <a class="list-group-item list-group-item-action" id="list-EL-list" data-bs-toggle="list" href="#list-el" role="tab" aria-controls="Grecia">EL-Arithmós Mētrṓou Fi-Pi-A</a>
                <a class="list-group-item list-group-item-action" id="list-HU-list" data-bs-toggle="list" href="#list-hu" role="tab" aria-controls="Ungheria">HU-ANUM</a>
                <a class="list-group-item list-group-item-action" id="list-IT-list" data-bs-toggle="list" href="#list-it" role="tab" aria-controls="Italia">IT- Partita Iva</a>
                <a class="list-group-item list-group-item-action" id="list-IE-list" data-bs-toggle="list" href="#list-ie" role="tab" aria-controls="Irlanda">IR-VAT</a>
                <a class="list-group-item list-group-item-action" id="list-LV-list" data-bs-toggle="list" href="#list-lv" role="tab" aria-controls="Lettonia">LV-PVN</a>
                <a class="list-group-item list-group-item-action" id="list-LT-list" data-bs-toggle="list" href="#list-lt" role="tab" aria-controls="Lituania">LT-PVM kodas</a>
                <a class="list-group-item list-group-item-action" id="list-LU-list" data-bs-toggle="list" href="#list-lu" role="tab" aria-controls="Lussemburgo">LU-TVA</a>
                <a class="list-group-item list-group-item-action" id="list-ML-list" data-bs-toggle="list" href="#list-ml" role="tab" aria-controls="Malta">MT-Vat No</a>
                <a class="list-group-item list-group-item-action" id="list-NL-list" data-bs-toggle="list" href="#list-nl" role="tab" aria-controls="Olanda">NL-Btw-nr</a>
                <a class="list-group-item list-group-item-action" id="list-PL-list" data-bs-toggle="list" href="#list-pl" role="tab" aria-controls="Polonia">PL-NIPC</a>
                <a class="list-group-item list-group-item-action" id="list-PT-list" data-bs-toggle="list" href="#list-pt" role="tab" aria-controls="Portogallo">PT-NIF</a>
                <a class="list-group-item list-group-item-action" id="list-ro-list" data-bs-toggle="list" href="#list-ro" role="tab" aria-controls="Romania">RO-CIF</a>
                <a class="list-group-item list-group-item-action" id="list-sk-list" data-bs-toggle="list" href="#list-sk" role="tab" aria-controls="Slovakia">SK-IČ DPH</a>
                <a class="list-group-item list-group-item-action" id="list-si-list" data-bs-toggle="list" href="#list-si" role="tab" aria-controls="Slovenia">SI-ID za DDV</a>
                <a class="list-group-item list-group-item-action" id="list-es-list" data-bs-toggle="list" href="#list-es" role="tab" aria-controls="Spagna">ES-NIF</a>
                <a class="list-group-item list-group-item-action" id="list-se-list" data-bs-toggle="list" href="#list-se" role="tab" aria-controls="Svezia">SE-Momsnr</a>
                <a class="list-group-item list-group-item-action" id="list-uk-list" data-bs-toggle="list" href="#list-uk" role="tab" aria-controls="RegnoUnito">GB-VAT Reg No</a>
              </div>
            </div>
            <div class="col-lg-9 mw-100">
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="list-au" role="tabpanel" aria-labelledby="list-au-list">
                  <h3 class=""><span class="flag-icon flag-icon-at"></span> Austria</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: UID - Umsatzsteuer-Identifikationsnummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'AT'+U+8 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : ATU12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Bundesministerium Funanzen
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.bmf.gv.at/">www.bmf.gv.at</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-be" role="tabpanel" aria-labelledby="list-be-list">
                  <h3 class=""><span class="flag-icon flag-icon-be"></span> Belgium</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: BTW-nr - BTW identificatienummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'BE'+ 8 digits + 2 check digits. At this time no numbers starting with "1" are issued, but this can happen any time. Note that the old numbering schema only had 9 characters, separated with dots (e.g. 999.999.9XX), just adding a zero in front and removing the dots makes it a valid number in the new schema. The check digits are calculated as 97 - MOD 97
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : BE01234567XX
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : General Administration of Taxation
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://finance.belgium.be/">finance.belgium.be</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only Two Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-bg" role="tabpanel" aria-labelledby="list-bg-list">
                  <h3 class=""><span class="flag-icon flag-icon-bg"></span> Bulgaria</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: ДДС номер - Идентификационен номер по ДДС Identifikacionen nomer po DDS
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 9 or 10 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : BG01234567890
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Национална агенция за приходите
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.nra.bg/">www.nra.bg</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only Two Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-hr" role="tabpanel" aria-labelledby="list-hr-list">
                  <h3 class=""><span class="flag-icon flag-icon-hr"></span> Croatia</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: PDV-ID OIB - PDV Id. Broj OIB
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'HR'+ 11 digit number utilising ISO 7064, MOD 11-10
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : HR12345678901
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Ministarstvo financija
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://mfin.gov.hr/">mfin.gov.hr</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only Two Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-cy" role="tabpanel" aria-labelledby="list-cy-list">
                  <h3 class=""><span class="flag-icon flag-icon-cy"></span> Cyprus</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: ΦΠΑ - Αριθμός Εγγραφής Φ.Π.Α.
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 9 characters
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : CY12345678L
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Υπουργείο Οικονομικών
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://mfin.gov.hr/">www.mof.gov.cy</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only Two Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-cz" role="tabpanel" aria-labelledby="list-cz-list">
                  <h3 class=""><span class="flag-icon flag-icon-cz"></span> Czech Republic</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: DIČ - Daňové identifikační číslo
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'CZ'+8-10 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : CZ123457978
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Ministerstvo financí ČR
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.mfcr.cz/">www.mfcr.cz</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-dk" role="tabpanel" aria-labelledby="list-dk-list">
                  <h3 class=""><span class="flag-icon flag-icon-dk"></span> Denmark</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: CVR - Momsregistreringsnummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 8 digits,  last digit is check digit
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : DK12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Skatteministeriet
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.skm.dk/">www.skm.dk</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-ee" role="tabpanel" aria-labelledby="list-ee-list">
                  <h3 class=""><span class="flag-icon flag-icon-ee"></span> Estonia</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: KMKR - Käibemaksukohustuslase number
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 9 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : EE123456789
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Maksu- ja Tolliamet
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.emta.ee/">www.emta.ee</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-fi" role="tabpanel" aria-labelledby="list-fi-list">
                  <h3 class=""><span class="flag-icon flag-icon-fi"></span> Finland</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: ALV nro Momsnummer - 	Arvonlisäveronumero Mervärdesskattenummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by FI + 7 digits + check digit, The check digit is calculated utilizing MOD 11-2. A VAT number can be generated from a Finnish Business ID (Y-tunnus) by adding a two-letter country code FI as a prefix and by omitting the dash.
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : FI12348975
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Verohallinto
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.vero.fi/">www.vero.fi</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-fr" role="tabpanel" aria-labelledby="list-fr-list">
                  <h3 class=""><span class="flag-icon flag-icon-fr"></span> France</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: TVA - Numéro d'identification à la taxe sur la valeur ajoutée
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'FR'+ 2 digits (as validation key ) + 9 digits (as SIREN), the first and/or the second value can also be a character
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : FR35123456789
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Ministère de l'Economie Des Finances et De le Relance
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.impots.gouv.fr/portail/">www.impots.gouv.fr</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-de" role="tabpanel" aria-labelledby="list-de-list">
                  <h3 class=""><span class="flag-icon flag-icon-de"></span> Germany</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: USt-IdNr - Umsatzsteuer-Identifikationsnummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 9 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : DE123456789
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Bundeszentralamt für Steuern
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.bzst.de/">www.bzst.de</a></span>
                  </p>
                  <p>
                    <i class="fad fa-exclamation-triangle"></i> VatID check Data: Suspended Check
                  </p>
                </div>
                <div class="tab-pane fade" id="list-el" role="tabpanel" aria-labelledby="list-el-list">
                  <h3 class=""><span class="flag-icon flag-icon-gr"></span> Greece</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: ΑΦΜ - Arithmós Forologikou Mētrṓou (Αριθμός Φορολογικού Μητρώου)
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by The language code EL according to ISO 639-1, followed by 9 digits, which equal the Greek taxpayer registration number Α.Φ.Μ. (A.F.M.) of the company or of the sole proprietor, i.e.: "Arithmós Mētrṓou Phi-Pi-A" = "EL" + "A.F.M.". The last digit is a check digit inherent of the Α.Φ.Μ. Beware: Due to the great similarity of both numbers - the one being the same number as the other, just having the prefix of EL - in practice sometimes the VAT identification number is wrongly called Α.Φ.Μ. (A.F.M.), but technically the VAT identification number and the taxpayer registration number Α.Φ.Μ. are different register numbers and should not be confused.
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : EL123456789
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Η Γ.Γ.Π.Σ.
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.gsis.gr/">www.gsis.gr</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-hu" role="tabpanel" aria-labelledby="list-hu-list">
                  <h3 class=""><span class="flag-icon flag-icon-hu"></span> Hungary</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: ANUM - Közösségi adószám
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 8 digits (the first 8 digits of the national tax number)
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : HU12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Nemzeti Adó- és Vámhivatal
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://nav.gov.hu/">nav.gov.hu</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-it" role="tabpanel" aria-labelledby="list-it-list">
                  <h3 class=""><span class="flag-icon flag-icon-it"></span> Italy</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: Partita IVA
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 11 digits (the first 7 digits is a progressive number, the following 3 means the province of residence, the last digit is a check number - The check digit is calculated using Luhn's Algorithm.)
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : IT1234578901
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Agenzia Delle Entrate
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.agenziaentrate.gov.it/portale/home/">www.agenziaentrate.gov.it</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>

                </div>
                <div class="tab-pane fade" id="list-ie" role="tabpanel" aria-labelledby="list-ie-list">
                  <h3 class=""><span class="flag-icon flag-icon-ie"></span> Ireland</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: VAT - Value added tax identification
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'IE'+7 digits and two letters
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : IE1234567FA
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Department of Finance
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="http://www.finance.gov.ie/">www.finance.gov.ie</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>

                </div>
                <div class="tab-pane fade" id="list-lv" role="tabpanel" aria-labelledby="list-lv-list">
                  <h3 class=""><span class="flag-icon flag-icon-lv"></span> Latvia</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: PVN - Pievienotās vērtības nodokļa reģistrācijas numurs
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 11 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : LV12345678901
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Valsts Ieņēmumu Dienesta
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.vid.gov.lv/">www.vid.gov.lv</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-lt" role="tabpanel" aria-labelledby="list-lt-list">
                  <h3 class=""><span class="flag-icon flag-icon-lt"></span> Lithuania</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: PVM kodas  - Pridėtinės vertės mokestis mokėtojo kodas
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 9 or 12 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : LT123456789012
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Valstybinė mokesčių inspekcija prie Lietuvos Respublikos finansų ministerijos
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.vmi.lt/">www.vmi.lt</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-lu" role="tabpanel" aria-labelledby="list-lu-list">
                  <h3 class=""><span class="flag-icon flag-icon-lu"></span> Luxembourg</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: No. TVA  - Numéro d'identification à la taxe sur la valeur ajoutée
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 8 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : LU12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : L’Etat du Grand-Duché de Luxembourg
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://pfi.public.lu/">pfi.public.lu/</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-ml" role="tabpanel" aria-labelledby="list-ml-list">
                  <h3 class=""><span class="flag-icon flag-icon-mt"></span> Malta</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: Vat No.  - Vat
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 8 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : MT12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Government of Malta
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://cfr.gov.mt/">cfr.gov.mt</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-nl" role="tabpanel" aria-labelledby="list-nl-list">
                  <h3 class=""><span class="flag-icon flag-icon-nl"></span> Netherlands</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: Btw-nr. - Btw-nummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'NL'+9 digits+B+2-digit company index
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : NL123456789B01
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Belastingdienst
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.belastingdienst.nl/">www.belastingdienst.nl</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only One Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-pl" role="tabpanel" aria-labelledby="list-pl-list">
                  <h3 class=""><span class="flag-icon flag-icon-pl"></span> Poland</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: NIP - Numer Identyfikacji Podatkowej
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 10 digits, the last one is a check digit; for convenience the digits are separated by hyphens (xxx-xxx-xx-xx or xxx-xx-xx-xxx for legal people), but formally the number consists only of digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : PL1234567890
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Ministerstwo Finansów
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.gov.pl/web/finanse/">www.gov.pl/web/finanse</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only one Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-pt" role="tabpanel" aria-labelledby="list-pt-list">
                  <h3 class=""><span class="flag-icon flag-icon-pt"></span> Portugal</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: NIPC - Número de Identificação de Pessoa Coletiva
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 9 digits; the last digit is the check digit. The first digit depends on what the number refers to, e.g.: 1-3 are regular people, 5 are companies.
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : PT123456789
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Autoridade Tributária e Aduaneira
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.portaldasfinancas.gov.pt/">www.portaldasfinancas.gov.pt</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only one Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-ro" role="tabpanel" aria-labelledby="list-ro-list">
                  <h3 class=""><span class="flag-icon flag-icon-ro"></span> Romania</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: CIF - Codul de identificare fiscală
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 8 digits
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : RO12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Ministerului Finantelor
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://mfinante.gov.ro/">mfinante.gov.ro</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only one Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-sk" role="tabpanel" aria-labelledby="list-sk-list">
                  <h3 class=""><span class="flag-icon flag-icon-sk"></span> Slovakia</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: IČ DPH - Identifikačné číslo pre daň z pridanej hodnoty
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'SK'+10 digits (number must be divisible by 11)
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : SK1234567890
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Ministerstvo financií Slovenskej republiky
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.mfsr.sk/">www.mfsr.sk</a></span>
                  </p>
                  <p>
                    <i class="fad fa-exclamation-triangle"></i> VatID check Data: Suspended Check
                  </p>
                </div>
                <div class="tab-pane fade" id="list-si" role="tabpanel" aria-labelledby="list-si-list">
                  <h3 class=""><span class="flag-icon flag-icon-si"></span> Slovenia</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: ID za DDV - 	Identifikacijska številka za DDV
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 'SI'+8 digits, last one is a check digit
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : SI12345678
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Finančna uprava Republike Slovenije
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.fu.gov.si/">www.fu.gov.si</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only one Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-es" role="tabpanel" aria-labelledby="list-es-list">
                  <h3 class=""><span class="flag-icon flag-icon-es"></span> Spain</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: NIF - Número de Identificación Fiscal (formerly named Código de Identificación Fiscal)
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by For companies it's either 'ES'+letter+8 digits or 'ES'+letter+7 digits+letter. Where the first letter defines the type of company and the following first 2 digits define the province where the company was registered. The last character is a control digit.
                    For individual people / freelancers, its either 'ES'+8 digits+letter (for Spaniards) or 'ES'+letter+7 digits+letter (for foreigners).
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : ESX1234567R
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Agencia Tributaria
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.agenciatributaria.es/">www.agenciatributaria.es</a></span>
                  </p>
                  <p>
                    <i class="fad fa-exclamation-triangle"></i> VatID check Data: Suspended Check
                  </p>
                </div>
                <div class="tab-pane fade" id="list-se" role="tabpanel" aria-labelledby="list-se-list">
                  <h3 class=""><span class="flag-icon flag-icon-se"></span> Sweden</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: Momsnr - VAT-nummer or momsnummer or momsregistreringsnummer
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by 12 digits, of which the last two are most often 01  (For sole proprietors who have several businesses the numbers can be 02, 03 and so on, since sole proprietors only have their personnummer as the organisationsnummer. The first 10 digits are the same as the Swedish organisationsnummer.
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : SE123456782201
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Skatteverket
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://skatteverket.se/">skatteverket.se</a></span>
                  </p>
                  <p>
                    <i class="fad fa-shield-check"></i> VatID check Data: Only one Year
                  </p>
                </div>
                <div class="tab-pane fade" id="list-uk" role="tabpanel" aria-labelledby="list-uk-list">
                  <h3 class=""><span class="flag-icon flag-icon-gb"></span> United Kingdom and <span class="flag-icon flag-icon-im"></span> Isle of Man</h3>
                  <br>
                  <p>
                    <i class="fad fa-building"></i> Vat Iditification for Business Company has Name: VAT Reg No - Value added tax registration number
                  </p>
                  <p>
                    <i class="fad fa-file-check"></i> It's composed by For the 9-digit scheme, the 2-digit block containing the 8th and 9th digits is always in the range 00 to 96 and is derived from a weighted modulus-97 check number (an identical algorithm is used for the 12-digit scheme, ignoring the extra 3-digit block)The current modulus-97 series ran out during 2010, so a parallel series of numbers was introduced from November 2009 for new registrations, restarting at 100 nnnn nn and following the same format but with the last two digits derived from an alternative algorithm known as "9755". The algorithm is identical to the one for the established series except that 55 is subtracted to give the check number (modulus 97), so the check number is either 55 less than or (if this would be negative) 42 greater than the check number that a VAT number in the established series would have if it were identical in the first seven digits.[25] The details of the 97−55 check algorithm were to be secret but are now available from HMRC on request
                    The GD and HA formats may also be formatted as GB888 8xxx yy for EU compatibility, where xxx is the 3-digit number from the short format and yy is the 2-digit modulus-97 check number.
                    Isle of Man registrations share the 9- and 12-digit formats with the UK, with GB as the country code prefix, but are distinguished by having 00 as the first two digits. Numbers with 01 to 09 in the first two digits are reserved by HM Revenue & Customs for UK non-VAT reference schemes
                  </p>
                  <p>
                    <i class="fad fa-brackets-curly"></i> Example : GB123456789
                  </p>
                  <p>
                    <i class="fad fa-balance-scale"></i> National Fiscal and tax Agency is : Department for Business, Energy & Industrial Strategy
                  </p>
                  <p>
                    <i class="fad fa-browser"></i> Web Site Agency: <span> <a class="btn btn-outline-info" href="https://www.gov.uk/">www.gov.uk</a></span>
                  </p>
                  <p>
                    <i class="fad fa-exclamation-triangle"></i> VatID check Data: Suspended Check
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </main>



</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'About',
    data () {
      return {

      }
    },
    components: {

    }
  }



</script>
<style>
  header {
    position: relative;
    background-image: url('https://images.unsplash.com/photo-1439392929143-75708d4118e2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
    height: 75vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }




</style>
