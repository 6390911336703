<template>
    <main>
        <template v-if="!louder" class="container p-2">
            <div class="align-items-center pt-5">
                <strong>Loading...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder">
            <div class="container p-2">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title "> {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }} </h5>
                                <h6 class="card-subtitle mb-2 text-muted">{{ this.$store.state.user.email }}</h6>
                                </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title"> State Cash</h5>
                                <h6 class="card-subtitle mb-2 text-muted"></h6>
                                <p class="card-text display-3"><i class="fad fa-chart-bar"></i> {{ Credits.credits.effective*0.15 }} € </p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-4">
                        <div class="card border-success text-success bgInverterGreen">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title"> Acredit </h5>
                                <h6 class="card-subtitle mb-2 text-muted"></h6>
                                <p class="card-text display-3"><i class="fad fa-chart-line"></i> {{ Credits.credits.acredit }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-danger text-danger bgInverterRed">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title"> Debit </h5>
                                <h6 class="card-subtitle mb-2 text-muted"></h6>
                                <p class="card-text display-3"><i class="fad fa-chart-line-down"></i> {{ Credits.credits.debit }} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-5">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <div class="list-group">
                                    <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1">New User This Year</h5>
                                            <p>{{ NewUser }} User</p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1">New Credit This Year</h5>
                                            <p>{{ NewCredit }} Credit</p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1">New View This Year</h5>
                                            <p>{{ NewView }} View</p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1">New Company This Year</h5>
                                            <p>{{ NewCompany }} Company</p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1">Value Generate on This Year</h5>
                                            <p>{{ ValueByNew*0.5 }} €</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <div class="list-group">
                                    <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                                        <div class="d-flex w-100 justify-content-center">
                                            <p>{{ NewUser - LastUser }} </p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-center">
                                            <p>{{ NewCredit - LastCredit }} </p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-center">
                                            <p>{{ NewView - LastView }} </p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-center">
                                            <p>{{NewCompany - LastCompany  }} </p>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-center">
                                            <p>{{ (ValueByNew - ValueByLast)*0.5  }} €</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <div class="list-group">
                                    <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                                        <div class="d-flex w-100 justify-content-between">
                                            <p>{{ LastUser }} User</p>
                                            <h5 class="mb-1">Last User Last Year</h5>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <p>{{ LastCredit }} Credit</p>
                                            <h5 class="mb-1">Last Credit Last Year</h5>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <p>{{ LastView }} View</p>
                                            <h5 class="mb-1">Last View Last Year</h5>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <p>{{ LastCompany }} Company</p>
                                            <h5 class="mb-1">Last Company Last Year</h5>
                                        </div>
                                    </a>
                                    <a href="#" class="list-group-item list-group-item-action">
                                        <div class="d-flex w-100 justify-content-between">
                                            <p>{{ ValueByLast*0.5 }} €</p>
                                            <h5 class="mb-1">Value Generate on Last Year</h5>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title "> Admin Function </h5>
                                <router-link  to="/admin/users" tag="a" class="card-link btn btn-outline-info btn-lg"><i class="fad fa-users"></i> Users List</router-link>
                                <router-link  to="/admin/query" tag="a" class="card-link btn btn-outline-info btn-lg"><i class="fad fa-database"></i> Query Form</router-link>
                                <router-link  to="/admin/comapanyclassification" tag="a" class="card-link btn btn-outline-info btn-lg"><i class="fad fa-list-ol"></i> Company Classification</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: "dashboard",
        data() {
            return {
                valureCredit : 0,
                History : [],
                louder : false,
            }
        },

        beforeMount() {
            this.Load();
        },
        computed: {
            Credits: function () {
                return this.getCredits
            },
            NewUser : function () {
              return this.getNewUser;
            },
            NewCredit : function () {
                return this.getNewCredit;
            },
            NewView : function () {
                return this.getNewViewAdmin;
            },
            NewCompany : function () {
                return this.getNewCompanyAdmin;
            },
            ValueByNew: function () {
                return (this.NewUser + this.NewCompany + this.NewView + this.NewCredit)/5;
            },
            LastUser : function () {
                return this.getLastUser;
            },
            LastCredit : function () {
                return this.getLastCredit;
            },
            LastView : function () {
                return this.getLastViewAdmin;
            },
            LastCompany : function () {
                return this.getLastCompanyAdmin;
            },
            ValueByLast: function () {
                return (this.LastUser + this.LastCompany + this.LastView + this.LastCredit)/5;
            },
            ...mapGetters({
                getCredits : 'Admin/getCreditsAdmin',
                getNewUser : 'Admin/getNewUserAdmin',
                getNewCredit : 'Admin/getNewCreditAdmin',
                getNewViewAdmin : 'Admin/getNewViewAdmin',
                getNewCompanyAdmin : 'Admin/getNewCompanyAdmin',
                getLastUser : 'Admin/getLastUserAdmin',
                getLastCredit : 'Admin/getLastCreditAdmin',
                getLastViewAdmin : 'Admin/getLastViewAdmin',
                getLastCompanyAdmin : 'Admin/getLastCompanyAdmin',
            })
        },
        methods: {
            ...mapActions({
                fetchCredit: 'Admin/fetchCreditAll',
                fetchNewUser : 'Admin/fetchUserNewYear',
                fetchCreditNewYear : 'Admin/fetchCreditNewYear',
                fetchViewNewYear : 'Admin/fetchViewNewYear',
                fetchCompanyNewYear : 'Admin/fetchCompanyNewYear',
                fetchLastUser : 'Admin/fetchUserLastYear',
                fetchCreditLastYear : 'Admin/fetchCreditLastYear',
                fetchViewLastYear : 'Admin/fetchViewLastYear',
                fetchCompanyLastYear : 'Admin/fetchCompanyLastYear',
            }),
            Load () {
                this.fetchNewUser();
                this.fetchViewNewYear();
                this.fetchCreditNewYear();
                this.fetchCompanyNewYear();
                this.fetchLastUser();
                this.fetchViewLastYear();
                this.fetchCreditLastYear();
                this.fetchCompanyLastYear();
                this.fetchCredit().then(() => { this.louder = true});
            },
        }

    }
</script>

<style scoped>

</style>

