import Vue from 'vue'
//import { sleep } from '@/lib/util'
//import { postJsonUno, postJsonDue, postJsonTre } from '@/jsonresponse'
import * as ApiSerivice from "@/helpers/api.service";
const state = {
    users: [],
    creditsAdmin : {},
    NewUser : 0,
    NewCredit : 0,
    NewView  : 0,
    NewCompany : 0,
    LastUser : 0,
    LastCredit : 0,
    LastView  : 0,
    LastCompany : 0,
    CompanyClassif : [],
    QueryResult : []

};
const getters = {
    getCreditsAdmin (state) {
        return state.creditsAdmin
    },
    getCreditAdmin: (state, getters) => (paramkey) => {
        if (getters.getCreditsAdmin) {
            const funf = getters.getCreditsAdmin.find(el => { return el.key === paramkey });
            if (funf) {
                return funf
            } else {
                return null
            }
        }
        return null
    },
    getNewUserAdmin (state) {
        return state.NewUser;
    },
    getNewCreditAdmin (state) {
        return state.NewCredit;
    },
    getNewViewAdmin (state) {
        return state.NewView;
    },
    getNewCompanyAdmin (state) {
        return state.NewCompany;
    },
    getLastUserAdmin (state) {
        return state.LastUser;
    },
    getLastCreditAdmin (state) {
        return state.LastCredit;
    },
    getLastViewAdmin (state) {
        return state.LastView;
    },
    getLastCompanyAdmin (state) {
        return state.LastCompany;
    },
    getListCompanyAdmin (state) {
        return state.CompanyClassif;
    },
    getReusltAdmin (state) {
        return state.QueryResult;
    },
    getUsersAdmin (state) {
        return state.users;
    },
};
const actions = {
    fetchCreditAll ({state, commit, dispatch}, payload) {
        ApiSerivice.get('/credit/all').then((res) => {
            commit('setCreditsAdmin', { credits:{ effective: res.data.effective, acredit : res.data.acredit, debit:  res.data.debit } });
        }).catch((err) => { });
    },
    fetchUserAll ({state, commit, dispatch}, payload) {
        ApiSerivice.get('/admin/user').then((res) => {
            commit('setUsersList',res.data);
        }).catch((err) => { });
    },
    fetchUserNewYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(users.id) as 'NewUser' from users WHERE YEAR(users.createdAt) = YEAR(CURDATE())"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
            commit('setNewUser', value.data.value.result[0].NewUser);
            }
        ).catch((err) => { });
    },
    fetchCreditNewYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(Credits.id) as 'NewU' from Credits WHERE YEAR(Credits.createdAt) = YEAR(CURDATE())"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setNewCredit', value.data.value.result[0].NewU);
            }
        ).catch((err) => { });
    },
    fetchViewNewYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(`Views`.id) as 'NewU' from `Views` WHERE YEAR(Views.createdAt) = YEAR(CURDATE())"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setNewView', value.data.value.result[0].NewU);
            }
        ).catch((err) => { });
    },
    fetchCompanyNewYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(companies.id) as 'NewU' from companies WHERE YEAR(companies.createdAt) = YEAR(CURDATE())"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setNewCompany', value.data.value.result[0].NewU);
            }
        ).catch((err) => { });
    },
    fetchUserLastYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(users.id) as 'LastUser' from users WHERE YEAR(users.createdAt) = (YEAR(CURDATE())-1)"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setLastUser', value.data.value.result[0].LastUser);
            }
        ).catch((err) => { });
    },
    fetchCreditLastYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(Credits.id) as 'LastU' from Credits WHERE YEAR(Credits.createdAt) = (YEAR(CURDATE())-1)"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setLastCredit', value.data.value.result[0].LastU);
            }
        ).catch((err) => { });
    },
    fetchViewLastYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(`Views`.id) as 'LastU' from `Views` WHERE YEAR(Views.createdAt) = (YEAR(CURDATE())-1)"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setLastView', value.data.value.result[0].LastU);
            }
        ).catch((err) => { });
    },
    fetchCompanyLastYear ({state, commit, dispatch}, payload) {
        var datat = { query : "SELECT COUNT(companies.id) as 'LastU' from companies WHERE YEAR(companies.createdAt) = (YEAR(CURDATE())-1)"};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setLastCompany', value.data.value.result[0].LastU);
            }
        ).catch((err) => { });
    },
    fetchCompanyClassf ({state, commit, dispatch}, payload) {
        var datat =  { query : "SELECT companies.`name`, companies.countryCode, COUNT(`Views`.userId) as 'ViewCompany', companies.id as 'ComID' FROM `Views` INNER JOIN companies on `Views`.companyId = companies.id GROUP BY companies.`name`ORDER by 3 DESC,2,1,4 LIMIT 0, 10 "};
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setListCompany', value.data.value.result);
            }
        ).catch((err) => { });
    },
    fetchQuery ({state, commit, dispatch}, formData) {
        var datat =  { query : formData };
        ApiSerivice.post('/admin/Query', datat).then((value) => {
                commit('setQueryResult', value.data.value.result);
            }
        ).catch((err) => { });
    },
    fetchReqRSTPSWForUser ({state, commit, dispatch}, userId) {
        ApiSerivice.post('/admin/User/RestPsw', {userid : userId }).then((value) => {

            }
        ).catch((err) => { });
    },
    fetchAddCreditforUser({commit, dispatch}, formData){
        ApiSerivice.post('/admin/user/credit', { value: formData.value , motivation: formData.motivation, userid: formData.userid, password: formData.password}).then((res) => {
            // console.log(res);
        }).catch((err) => {
            if(err){
                //console.log(err);
            }
        })
    },
};
const mutations = {
    setCreditsAdmin (state, payload) {
        state.creditsAdmin = payload
    },
    setCreditAdmin (state, payload) {
        Vue.set(state.creditsAdmin, payload.key, payload)
    },
    setNewUser (state, payload) {
        state.NewUser = payload
    },
    setNewCredit (state, payload) {
        state.NewCredit = payload
    },
    setNewView (state, payload) {
        state.NewView = payload
    },
    setNewCompany (state, payload) {
        state.NewCompany = payload
    },
    setLastUser (state, payload) {
        state.LastUser = payload
    },
    setLastCredit (state, payload) {
        state.LastCredit = payload
    },
    setLastView (state, payload) {
        state.LastView = payload
    },
    setLastCompany (state, payload) {
        state.LastCompany = payload
    },
    setListCompany (state, payload) {
        state.CompanyClassif = payload
    },
    setQueryResult(state, payload) {
        state.QueryResult = payload
    },
    setUsersList(state, payload) {
        state.users = payload
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
