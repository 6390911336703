<template>
    <div class="container mt-5">
        <h1 class="title">Credit History</h1>
        <template v-if="!louder">
            <div class="d-flex align-items-center">
                <strong>Loading...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder">
            <div class="row">
                <div class="card col-lg-12 center shadow rounded ">
                    <div class="card-body">
                        <h5 class="card-title">Your Credit</h5>
                        <h6 class="card-subtitle mb-2">of Today</h6>
                        <p class="card-text" v-if="Credits.credits.disponiblecredit" >Credit : {{ Credits.credits.disponiblecredit }} VatId</p>
                        <router-link tag="a" to="/charge" class="card-link btn btn-outline-primary btn-sm"><i class="fad fa-charging-station"></i> Credit Recharge</router-link>
                        <router-link tag="a" to="/sendVat" class="card-link btn btn-outline-primary btn-sm"> <i class="fad fa-battery-bolt"></i> Free Credit Recharge</router-link>
                    </div>
                </div>
            </div>
            <br/>
            <div class="row text-center d-flex justify-content-center">
                <div class="card col-lg-3 m-3 shadow rounded" v-bind:class="classColorCardCss(el.value)" v-for="el in Credits.credits.history.slice().reverse()" :key = "el.id" style="height: 15rem">
                    <div class="card-body ">
                        <h5 class="card-title">Unic Transation ID #{{ el.id }}</h5>
                        <h6 class="card-subtitle mb-2 display-5"><template v-if="el.value > 0">+</template>{{ el.value }}</h6>
                        <p class="card-text">{{ el.motivation }}</p>
                        <p class="card-text  mt-1">{{ new Date(el.createdAt).toDateString() }}</p>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: "Credit",
        data() {
            return {
                valureCredit : 0,
                History : [],
                louder : false,
            }
        },
        beforeMount() {
           this.Load();
        },
        computed: {
            Credits: function () {
              return this.getCredits
            },
          ...mapGetters({
              getCredits : 'Credit/getCredits'
          })
        },
        methods: {
            ...mapActions({
                fetchCredit: 'Credit/fetchCredit'
            }),
            Load () {
                this.fetchCredit().then(() => { this.louder = true});

            },
            classColorCardCss (value) {
                if (value > 0) {
                    return "border-success text-success bgInverterGreen ";
                } else if (value < 0) {
                    return "border-danger text-danger bgInverterRed";
                } else {
                    return "border-primary";
                }
            },
        }
    }
</script>

<style >
    .bgInverterRed{
        transition: 0.3s;
    }
    .bgInverterGreen{
        transition: 0.3s;
    }


.bgInverterRed:hover {
    color: #e3e3e3!important;
    background-color: #dc3545;
}
.bgInverterGreen:hover {
    color: #e3e3e3!important;
    background-color: #198754;
}



</style>