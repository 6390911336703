var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[(!_vm.presOrder)?[_vm._m(0)]:_vm._e(),_c('div',[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-4 col-sm-6 mb-4 mx-auto"},[_c('div',{staticClass:"pricingTable"},[_vm._m(1),_vm._m(2),_c('br'),_c('ul',{staticClass:"pricing-content"},[_c('h6',[_vm._v("First Month it's Free")]),(_vm.hasFee)?[_vm._m(3)]:_vm._e(),(!_vm.hasFee)?[(_vm.validateCard)?[_c('li',[_c('button',{staticClass:"btn btn-outline-primary btn-lg",on:{"click":function($event){$event.preventDefault();return _vm.order.apply(null, arguments)}}},[(_vm.send)?[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}})]:_vm._e(),_vm._v(" Subscrive! ")],2)])]:_vm._e(),(!_vm.validateCard)?[_vm._m(4)]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"row"})])])])])],2),_c('div',{staticClass:"modal fade",attrs:{"id":"DeactivateFee","aria-hidden":"true","aria-labelledby":"DeactivateFeeLabel","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-fullscreen-sm-down modal-xl"},[_c('div',{staticClass:"modal-content"},[_vm._m(5),_c('div',{staticClass:"modal-body"},[_c('h4',[_vm._v("Confirm Deactivate Your VatID.Cloud Fee?")]),_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_vm._v("Remain Active")]),_c('button',{staticClass:"btn btn-danger btn-lg",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.Deactivate.apply(null, arguments)}}},[_vm._v("Deactivate My Fee")])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"addCardtoggel","aria-hidden":"true","aria-labelledby":"addCardtoggelLabel","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-fullscreen-sm-down modal-xl"},[_c('div',{staticClass:"modal-content"},[_vm._m(6),_c('div',{staticClass:"modal-body"},[_c('add-card',{attrs:{"add-sub":true}})],1)])])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center mt-5"},[_c('h1',{staticClass:"heading-title"},[_vm._v("Active Subscription")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricingTable-header"},[_c('h3',{staticClass:"heading"},[_vm._v("VatID.Cloud Fee")]),_c('div',{staticClass:"price-value"},[_vm._v(" 5.90 "),_c('span',{staticClass:"currency"},[_vm._v("€")]),_c('span',{staticClass:"month"},[_vm._v("/MM")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b',[_vm._v(" € 0.19 for Day!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('button',{staticClass:"btn btn-outline-danger btn-lg",attrs:{"data-bs-toggle":"modal","href":"#DeactivateFee"}},[_vm._v(" Deactivate Fee")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('button',{staticClass:"btn btn-success btn-lg",attrs:{"data-bs-toggle":"modal","href":"#addCardtoggel"}},[_vm._v(" Subscrive!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h1',{staticClass:"modal-title fs-5",attrs:{"id":"DeactivateFeeLabel"}},[_vm._v("Confirm Choise?")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h1',{staticClass:"modal-title fs-5",attrs:{"id":"addCardtoggelLabel"}},[_vm._v("Add Your Card")]),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-5"},[_c('p',[_vm._v("for info status you order or problem on sub open ticket o send mail to info@vatid.cloud "),_c('br'),_vm._v(" VatID.Cloud - Pellizzaro Development Projects - Europe")])])
}]

export { render, staticRenderFns }