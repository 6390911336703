<template>
    <div>
            <div class="container">
                <template v-if="!presOrder">
                  <div class="row text-center mt-5">
                    <h1 class="heading-title">Top Up Your Credit</h1>
                    <h2 class="subtitle">Clear, Simple and Transparent Prices</h2>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-4 col-sm-6 mb-4 mx-auto ">
                      <div class="pricingTable">
                        <div class="pricingTable-header">
                          <h3 class="heading">Charge Credit</h3>
                          <span class="subtitle">total</span>
                          <div class="price-value"> {{ set_value * price }}
                            <span class="currency">€</span>
                          </div>
                        </div>
                        <ul class="pricing-content">
                          <li>+{{ set_value }} credit</li>
                        </ul>
                        <div class="row">
                          <div class="col-6 text-center mx-auto">
                            <div class="input-group">
                              <select class="form-select" id="inputGroupSelect04" aria-label="Select Quantity" v-model="set_value">
                                <option selected>Choose...</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="2000">2000</option>
                                <option value="5000">5000</option>
                                <option value="10000">10000</option>
                              </select>
                            </div>
                          <template v-if="!this.HaveInvoiceDate.haveInvoiceDate">
                           <p><i class="fa-solid fa-triangle-exclamation"></i> You Not Have a Billing Information completed, please go to user page and insert a request data</p>
                            <router-link to="/user" tag="a" class="btn btn-outline-primary btn-lg"><i class="fad fa-house-user"></i> My User Page</router-link>
                          </template>
                            <template v-if="this.HaveInvoiceDate.haveInvoiceDate">
                              <div class="row mt-2">
                                <div class="col-6 text-center mx-auto">
                                  <button class="btn btn-outline-primary btn-lg" @click.prevent="order">
                                    <template v-if="send"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></template>
                                    Order
                                  </button>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                        <!--<a href="https://buy.stripe.com/test_00gdS72h0eSU4928wx" class="read">Order<i class="fa fa-angle-right"></i></a>-->
                      </div>
                    </div>
                  </div>
                </template>
              <template v-if="presOrder">
                <div class="row text-center mt-5 mb-6">
                  <blockquote class="blockquote">
                    <p>Select the quantity and Press Order</p>
                  </blockquote>
                  <div class="col-lg-12">
                    <p>Order Details</p>
                    <article class="card">
                      <div class="card-body">
                        <h3>Order a {{ set_value }} Credit VatID</h3>
                        <p>for User {{this.$store.state.user.name}} {{this.$store.state.user.surname}} - {{ this.$store.state.user.email}}</p>
                        <br />
                        <h2> Complete the data entry for the payment and then press the Pay button, afterwards the credits will be credited within 24 hours from the order </h2>
                        <h3>Thank you from VatId.Cloud</h3>
                      </div>
                    </article>
                  </div>
                </div>
              </template>
            </div>
        <div class="m-5">
          <p>for info status you order or problem on credit open ticket o send mail to info@vatid.cloud <br /> VatID.Cloud - Pellizzaro Development Projects - Europe</p>
        </div>
    </div>

</template>

<script>
import * as ApiService from '@/helpers/api.service'
import InvoiceDate from "@/components/invoiceDate";
import { mapGetters, mapActions } from 'vuex'
    export default {
        name: "charge",
      components: {InvoiceDate},
      data() {
            return {
                fee : false,
                annual : false,
                presOrder : false,
                verbose : '',
                creditForm : '',
                year : 'year',
                month : 'month',
                price : 0.50,
                set_value : 1,
                price_tot : 0.00,
                send : false,
                serverDate : '',
                Simply : {
                    name : 'Simply',
                    description : 'for 15 < month searches',
                    monthPrice : 2,
                    annualPrice : 10,
                    credit : 20,
                },
                Business:  {
                    name : 'Business',
                    description : 'for 200 < month searches',
                    monthPrice : 20,
                    annualPrice : 260,
                    credit : 99
                },
                Enterprice:  {
                    name : 'Enterprice',
                    description : 'for 2500 < month searches',
                    monthPrice : 200,
                    annualPrice : 2300,
                    credit : 1000
                },
                chargeCredit : [{ value : 1, pay : 0.50, id:'price_1ITvZXGkZgdPCt2V2cQwmqeS'}],
                unlimietd : {
                    price : 10000,
                    id: "price_1ITvcMGkZgdPCt2VKwooEXC9",
                }
            }
        },
      beforeMount() {
        this.getHaveInvoiceDate();
      },
      methods : {
        ...mapActions({
          getHaveInvoiceDate : 'getHaveInvoiceDate',

        }),
          changeStatus() {
            this.presOrder = !this.presOrder;
          },
          order () {
            this.send = true
            ApiService.post("/price/order", { qta : this.set_value, price : this.price }).then(
                value => {
                  this.serverDate = value;
                  //this.creditForm = `<iframe class="col-12" height="600" frameBorder="0" style="border: 0" src="http://localhost:3000/checkout.html?pk_pay=${value.data.client_secret}" allowFullScreen></iframe>`
                  this.send= false;
                  this.changeStatus();
                  window.open(`https://invoice.apserial.it/checkout.html?pk_pay=${value.data.client_secret}`, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes,allow="payment"')
                }
            ).catch(() => {
              console.log("ERRROE");
            })
          }
      },
      computed : {
        ...mapGetters({
          HaveInvoiceDate : 'haveInvoiceDate',
        })
      }
    }
</script>

<style scoped>
    .home
    {
        padding: 50px 0;
    }
    .heading-title
    {
        margin-bottom: 50px;
    }

    .pricingTable{
        border: 2px solid #e3e3e3;
        text-align: center;
        position: relative;
        padding-bottom: 40px;
        transform: translateZ(0px);
    }

    .pricingTable:before,
    .pricingTable:after{
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        bottom: -2px;
        right: -2px;
        z-index: -1;
        transition: all 0.5s ease 0s;
    }

    .pricingTable:before{
        border-right: 2px solid #0d6efd;
        border-left: 2px solid #0d6efd;
        transform: scaleY(0);
        transform-origin: 100% 0 0;
    }

    .pricingTable:after{
        border-bottom: 2px solid #0d6efd;
        border-top: 2px solid #0d6efd;
        transform: scaleX(0);
        transform-origin: 0 100% 0;
    }

    .pricingTable:hover:before{
        transform: scaleY(1);
    }

    .pricingTable:hover:after{
        transform: scaleX(1);
    }

    .pricingTable .pricingTable-header{
        background: #0d6efd;
        color: #fff;
        margin: -2px -2px 35px;
        padding: 40px 0;
    }

    .pricingTable .heading{
        font-size: 30px;
        font-weight: 600;
        margin: 0 0 5px 0;
    }

    .pricingTable .subtitle{
        font-size: 14px;
        display: block;
    }

    .pricingTable .price-value{
        font-size: 72px;
        font-weight: 600;
        margin-top: 10px;
        position: relative;
        display: inline-block;
    }

    .pricingTable .currency{
        font-size: 45px;
        font-weight: normal;
        position: absolute;
        top: 2px;
        left: -30px;
    }

    .pricingTable .month{
        font-size: 20px;
        position: absolute;
        bottom: 17px;
        right: -40px;
    }

    .pricingTable .pricing-content{
        list-style: none;
        padding: 0;
        margin-bottom: 30px;
    }

    .pricingTable .pricing-content li{
        font-size: 16px;
        color: #7a7e82;
        line-height: 40px;
    }

    .pricingTable .read{
        display: inline-block;
        border: 2px solid #7a7e82;
        border-right: none;
        font-size: 14px;
        font-weight: 700;
        color: #7a7e82;
        padding: 9px 30px;
        position: relative;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
    }

    .pricingTable .read:hover{
        border-color: #0d6efd;
        color: #0d6efd;
    }

    .pricingTable .read i{
        font-size: 19px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        right: 15px;
        transition: all 0.3s ease 0s;
    }

    .pricingTable .read:hover i{
        right: 5px;
    }

    .pricingTable .read:before,
    .pricingTable .read:after{
        content: "";
        display: block;
        height: 30px;
        border-left: 2px solid #7a7e82;
        position: absolute;
        right: -11px;
        transition: all 0.3s ease 0s;
    }

    .pricingTable .read:before{
        bottom: -6px;
        transform: rotate(45deg);
    }

    .pricingTable .read:after{
        top: -6px;
        transform: rotate(-45deg);
    }

    .pricingTable .read:hover:before,
    .pricingTable .read:hover:after{
        border-left-color: #0d6efd;
    }

    @media screen and (max-width: 990px){
        .pricingTable{ margin-bottom: 25px; }
    }
</style>
