<template>
    <div class="container mt-4">
        <h2>Company Profile</h2>
        <div class="row text-center d-flex justify-content-center">
                    <div class="card  m-1 col-md-12" v-if="CreditValid">
                        <div class="card-body">
                            <p class="text-muted"><span class="flag-icon" v-bind:class="classFlag"></span> {{ company.countryCode}}</p>
                            <h5 class="card-title"> <i class="fad fa-route-interstate"></i> {{ company.countryCode}}{{ company.vatNumber}}</h5>
                            <h5 class="card-title"><i class="fad fa-id-card"></i> {{ company.name}}</h5>
                            <p class="card-text"><i class="fad fa-shield-check"></i> is Active : {{ company.active}}</p>
                            <p class="card-text"><i class="fad fa-city"></i> {{ company.address}}</p>
                        </div>
                    </div>
        </div>
        <div id="insert">
            <span v-html="compne"></span>
        </div>
    </div>
</template>


<script>
    import * as ApiService from '@/helpers/api.service'
    import * as GetISO from '@/helpers/convertToNationISO'
    export default {
        name: "CompanyMasterData",
        data() {
            return {
                company : {},
                link: ``,
                compne : '',
                CreditValid : true,
                classFlag : 'flag-icon-'
            }
        },
        beforeMount() {
            this.getCompanyData();
        },
        computed: {
            paramKey: function () {
                return this.$route.params.key
            },
            flag: function () {
                return GetISO.GetISoCodeNation(this.company.countryCode);
            }
        },
        methods : {
            getCompanyData() {
                ApiService.get(`company/${this.paramKey}`).then((res) => {
                    if(res.data.message){
                        this.CreditValid = false;
                        //console.log('NOT Credit');
                        this.$router.push('/401');
                    }
                    else {
                        this.company = res.data.Company;
                        this.classFlag = this.classFlag + this.flag.toLowerCase();
                        this.link = `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_API_GOGLE_KEY}&q=${encodeURIComponent(res.data.Company.address)}`;
                        this.compne = `<iframe class="col-12"  height="600"  frameborder = "0" style = "border: 0" src="${this.link}" allowfullscreen></iframe>`;
                        this.AddViewCompanyData();
                    }
                }).catch((err) => {
                    this.CreditValid = false;
                    //console.log('NOT Credit');
                    this.$router.push('/401');
                    //console.log(err);
                });
            },
            AddViewCompanyData() {
                if(this.CreditValid){
                    ApiService.post(`company/${this.paramKey}/view`).then((res) => {
                    }).catch((err) => { });
                  this.$notify({
                    title: 'View Company',
                    type : 'success',
                    duration: 10000,
                    speed: 1000,
                    text: `Hi! ${this.$store.getters.user.name},\n <br> Thanks for your View a ${this.company.name}! (+1 View History)`
                  });
                }
                else {
                    console.log('NOT Credit');
                }

            },
        }
    }
</script>

<style scoped>

</style>
