<template>
    <div class="container mt-4">
        <h2>Send Vat ID UE Company</h2>
        <p class="text-muted">enter a VatUeId if it is not present in our database we will credit you with a credit to carry out other searches in our portal</p>
        <div class="row center text-center mt-5">
            <div class="">
                <form @submit.prevent="onSubmit" >
                    <div class="input-group mb-3 row center" >
                        <label for="input" class="col-lg-2 col-form-label col-form-label-lg">Company VatId</label>
                        <input id="input" type="text" class="form-control form-control-lg col-lg-8" placeholder="IT012348567081" aria-label="VatID Research" aria-describedby="button-addon2" v-model="text">
                        <button class="btn btn-outline-primary col-lg-2" type="submit" id="button-addon2">Send</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center">
            <div class="alert alert-danger" role="alert" v-if="error">
                Attection content not valid!
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center" v-if="resech">

            <template v-if="louder">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </template>
            <template v-if="!louder">
              <template>
                  <div class="card  m-1 col-md-3" >
                      <div class="card-body">
                          <p class="text-muted">{{ company.countryCode}}</p>
                          <h5 class="card-title">{{ company.name}}</h5>
                          <p class="card-text">{{ company.address}}</p>
                          <router-link tag="a" class="btn btn-primary" :to="`/company/${company.id}`">View</router-link>
                      </div>
                  </div>
              </template>
                <template  v-if="company.length >= 0">
                    <h6 class="subtitle"> No other Record Result for this Research</h6>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import * as ApiService from '@/helpers/api.service'
    export default {
        name: "sendVatID",
        data() {
            return  {
                louder : false,
                resech : false,
                text:'',
                error : false,
                company: {}
            }
        },
        methods: {
            onSubmit() {
                if (this.text === ''){
                    this.error = true;
                }else {
                    this.error = false;
                    this.louder = true;
                    this.resech = true;
                    ApiService.post(`/company/`,{ id:this.text } ).then((res) => {
                        if(!res.data.company){
                            this.louder = false;
                            this.company = {
                                id: "1",
                                countryCode : "Sorry",
                                name: "This Company is present on db",
                                address : "get a new vat and resend request"
                            }
                        }
                        this.company = res.data.company;
                        this.louder = false;
                        ApiService.post(`/credit/`, { value: '+2',motivation : "insert data" }).then((res) => {
                          this.$notify({
                            title: 'Add Credit',
                            type : 'success',
                            duration: 10000,
                            speed: 1000,
                            text: `Hi ${this.$store.getters.user.name},\n<br> Thanks you for data! (+2 Credit)`
                          });
                        }).catch((err) => { console.log(err)});
                    }).catch((err) =>{
                        this.louder = false;
                        this.company = {
                            id: "1",
                            countryCode : "Sorry",
                            name: "This Company is present on db",
                            address : "get a new vat and resend request"
                        }
                    })

                }

            }
        }
    }
</script>

<style scoped>

</style>