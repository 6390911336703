<template>
  
  <div>
    <main>
      <div class="text-center border-bottom bg-primary text-bg-primary maxvp d-flex justify-content-center align-items-center">
        <div class="mw-100 maxWitvp text-center">
          <h1 class="display-1 fw-bold">VatID.Cloud</h1>
          <div class="col-lg-9 col-sm-7 mx-auto mt-2">
            <div class="input-group input-group-lg mb-3">
              <input type="text" class="form-control" aria-label="Search Company by Name on VatID.Cloud"  placeholder="Search Company by Name on VatID.Cloud"  v-model="search_words">
            </div>
            <br />
            <button type="submit" class="btn btn-lg btn-outline-light" data-bs-toggle="modal" href="#exampleModalToggle" @click.pr.prevent="send"><i class="fa-duotone fa-magnifying-glass"></i> Search</button>
          </div>
        </div>
      </div>

      <!-- -->
      <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Search on VatID.Cloud Database</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <search-vat :key-search="searchValue"></search-vat>
            </div>
          </div>
        </div>
      </div>

      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img src="lady_browser.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">Are you looking for information about European companies?</h1>
            <p class="lead">VatID.Cloud allows searching by name in a single portal for the minimum information of European companies available in its database</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container col-xxl-8 px-4 py-5">
        <div class="row  align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img src="man_browser.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">One goal just Simplify!</h1>
            <p class="lead">We created this platform to allow everyone in a quick and easy way to retrieve this information from across the euro area</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>
            </div>
          </div>
        </div>
      </div>

      <div class=" container col-xxl-8 px-4 py-5">
        <div class="row  align-items-center g-5 py-5">
            <div class="col-12">
              <h4 class="display-5 fw-bold lh-1 mb-3">What do you get with VatID.Cloud? </h4>
            </div>
        </div>
        <div class="row  align-items-center g-5 py-5">
          <div class="col-12 col-sm-12 col-lg-4">
            <img src="man_invoice.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            <div>
              <p class="lead mt-5">The minimum details you need to issue your invoices</p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-4">
            <img src="man_ceo.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            <div>
              <p class="lead mt-5">More Time to Dedicate to Your Business, Thanks to Less Time for Information Retrieval</p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-4">
            <img src="man_cfo.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            <div>
              <p class="lead mt-5">Security that the data has been validated</p>
            </div>
          </div>
        </div>
      </div>


      <div class="container my-5">
        <div class="pricing-header p-3 pb-md-4 mx-auto text-center ">
          <h4 class="display-5 fw-bold lh-1 mb-3">Pricing</h4>
          <p class="fs-5 text-muted">Explore our pricing options to find the perfect solution that fits your needs and budget</p>
        
          <div>
            <div class="row  justify-content-around align-items-center row-cols-1 row-cols-md-3 mb-3 text-center">
              <div class="col">
                <div class="card mb-4 rounded-3 shadow-sm border-primary">
                  <div class="card-header py-3 bg-transparent border-primary">
                    <h4 class="my-0 fw-normal"><b>Free</b></h4>
                  </div>
                  <div class="card-body">
                    <h1 class="card-title pricing-card-title">€ 0<small class="text-muted fw-light">/mo</small></h1>
                    <ul class="list-unstyled mt-3 mb-4">
                      <li>Unlimited searches</li>
                      <li>Access to all of our master records</li>
                      <li>History views</li>
                    </ul>
                    <!-- <button type="button" class="w-100 btn btn-lg btn-outline-primary">Sign up for free</button> -->
                    <router-link  v-if="!auth" to="/register" custom v-slot="{ navigate }">
                       <button @click="navigate" @keypress.enter="navigate" class="w-100 btn btn-lg btn-outline-primary">Sign up for free</button>
                    </router-link>
                    <router-link  v-if="auth" to="/register" custom v-slot="{ navigate }">
                       <button @click="navigate" @keypress.enter="navigate" class="w-100 btn btn-lg btn-outline-secondary" disabled>Your Account is Active</button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-4 rounded-3 shadow-sm border-primary">
                  <div class="card-header py-3 bg-transparent border-primary">
                    <h4 class="my-0 fw-normal"><b>Pro</b></h4>
                  </div>
                  <div class="card-body">
                    <h1 class="card-title pricing-card-title">€ 5.90<small class="text-muted fw-light">/mo</small></h1>
                    <ul class="list-unstyled mt-3 mb-4">
                      <li>Unlimited searches</li>
                      <li>Access to all of our master records</li>
                      <li>History views</li>
                      <li>Access to REST API by VatID.Cloud</li>
                    </ul>
                    <router-link  v-if="!auth" to="/register?returnPage=/fee" custom v-slot="{ navigate }">
                        <button @click="navigate" @keypress.enter="navigate" class="w-100 btn btn-lg btn-primary">Sign up</button>
                    </router-link>
                    <router-link  v-if="auth" to="/fee" custom v-slot="{ navigate }">
                        <button @click="navigate" @keypress.enter="navigate" class="w-100 btn btn-lg btn-primary">Active Fee</button>
                    </router-link>
                    <a></a>
                  </div>
                </div>
              </div>
            </div>

            <h2 class="display-6 text-center mb-4">Compare plans</h2>

            <div class="table-responsive">
              <table class="table text-center ">
                <thead>
                  <tr>
                    <th style="width: 34%;"></th>
                    <th style="width: 22%;">Free</th>
                    <th style="width: 22%;">Pro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" class="text-start">Unlimited searches</th>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-start">Access to 15.000.000 master records</th>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th scope="row" class="text-start">History views</th>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-start">Access to REST API by VatID.Cloud</th>
                    <td></td>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-start">Our support to develop your integration with VatID.Cloud</th>
                    <td></td>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                  </tr>
                  <tr>
                    <th scope="row" class="text-start">Accessing the Download Request of CSV Lists
                      <br />
                      <small class="blockquote-footer">this service requires additional costs of 0.50€/record</small>
                    </th>
                    <td></td>
                    <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class=" container col-xxl-8 px-4 py-5">
        <div class="row align-items-center g-5 py-5">
          <div class="col-12">
            <h4 class="display-5 fw-bold lh-1 mb-3">What are you waiting for again? </h4>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row bgFooter text-light">
        <div class="col-lg-6">
          <div class="text-start m-4">
            <h4 class="display-4 fw-bold text-white">VatID</h4>
            <h6>Project by</h6>
            <h6 class="">Pellizzaro Development Projects</h6>
            <h6 class="">di Pellizzaro Alessio - IT04380170243 - San Martino Buon Albergo, Italy</h6>
            <h6>© 2020 - {{ new Date().getFullYear() }} - @VatID.Cloud - <a class="text-decoration-none text-light" href="mailto:info@vatid.cloud?subject=Information&body=Hello!" >info@vatid.cloud</a> - Europe</h6>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="text-start m-4 text-light">
            <ul class="list-unstyled list-group list-group-flush">
              <li class="">
                <router-link tag="a" to="/" class="fs-4 text-decoration-none text-light">Home</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/Login" class="fs-4 text-decoration-none text-light" >Login</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/Register" class="fs-4 text-decoration-none text-light" v-if="!betaState" >Register</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/Charge" class="fs-4 text-decoration-none text-light" v-if="!betaState" >Charge</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="text-start m-4 text-light">
            <ul class="list-unstyled list-group list-group-flush">
              <li class="">
                <router-link tag="a" to="/search" class="fs-4 text-decoration-none text-light" >Search by Name</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/searchByVat" class="fs-4 text-decoration-none text-light" >Search by Vat</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/user" class="fs-4 text-decoration-none text-light" >User</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/history" class="fs-4 text-decoration-none text-light" >History User</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="text-start m-4 text-light">
            <ul class="list-unstyled list-group list-group-flush">
              <li class="">
                <router-link tag="a" to="/ticket" class="fs-4 text-decoration-none text-light" >Support</router-link>
              </li>
              <li class="">
                <a href="https://www.iubenda.com/privacy-policy/14075729" class="fs-4 text-decoration-none text-light" title="Privacy Policy ">Privacy Policy</a>
              </li>
              <li class="">
                <a href="https://www.iubenda.com/privacy-policy/14075729/cookie-policy" class="fs-4 text-decoration-none text-light">Cockies</a>
              </li>
              <li class="">
                <a href="https://www.iubenda.com/terms-and-conditions/14075729" class="fs-4 text-decoration-none text-light">Terms & Condition</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>

</template>

<script>
import * as GetISO from "@/helpers/convertToNationISO";
export default {
  name: 'NewHome',
  data () {
    return {
      text_send : "",
      search_words : ""
    }
  },
  beforeMount(){

  },
  computed: {
    betaState: function () {
      return process.env.VUE_APP_BETA_STATE;
    },
    searchValue: function () {
      if(this.text_send !== ""){
        return this.text_send
      }else {
        return null
      }
    },
    auth() {
        return this.$store.getters.isAuth;
      },
  },
  methods : {
    send() {
      if(this.search_words !== ""){
         this.text_send = this.search_words;
         this.search_words = "";
      }
    }
  }
}



</script>
<style>

.maxvp {
  height: 80vh !important;
}
.maxWitvp {
  width: 100vh !important;
}

.bgFooter {
  background-color: #4D4D4D;
}
.search-container {
  display: flex;
  justify-content: center;
}

/* Stile per la barra di ricerca */
.search-box {
  width: 75vh;
  height: 5vh;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 25px;
}

/* Stile per il pulsante di ricerca */
.search-button {
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

/* Stile al passaggio del mouse sul pulsante di ricerca */
.search-button:hover {
  background-color: #357ae8;
}
</style>
