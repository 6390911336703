<template>
    <main class="container">
        <template v-if="!louder" >
            <div class="align-items-center pt-5">
                <strong>Loading Page...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template  v-if="louder" >
            <h1 class="mt-2 display-4">Request Support</h1>
            <div class="row">
                <div class="col-lg-4">
                    <div class="card shadow shadow-lg m-2">
                        <div class="card-body">
                            <h3>You have a Question?</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card shadow shadow-lg m-2">
                        <div class="card-body">
                            <h3>You have a Suggest?</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card shadow shadow-lg m-2">
                        <div class="card-body">
                            <h3>Bug on Portal? </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card shadow shadow-lg m-2">
                        <div class="card-body">
                            <h3>Open a Ticket</h3>
                            <button class="btn btn-primary mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#sendform"  aria-expanded="false" aria-controls="sendform">Create a New Ticket</button>
                            <form id="sendform" class="collapse" @submit.prevent="sendReq">
                                <div class="mb-3">
                                    <label for="titleinput" class="form-label">Title</label>
                                    <input type="text" class="form-control" id="titleinput" placeholder="My Ticket Title" v-model="title">
                                </div>
                                <div class="mb-3">
                                    <label for="TextInput" class="form-label">Text</label>
                                    <textarea class="form-control" id="TextInput" rows="3" placeholder="My Ticket Text" v-model="text"></textarea>
                                </div >
                                <div class="mb-3" >
                                    <button class="btn btn-outline-primary" type="submit">Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <h3>My Ticket List</h3>
                <template v-if="ListTicket.ticket.length > 0">
                    <div class="col col-lg-12">
                        <div class="card shadow shadow-lg m-2" v-bind:class="classSet(index)" v-for="(el, index) in ListTicket.ticket" :key="el.id" @mouseover="selectEl(index)">
                            <div class="card-body">
                                <h4>#{{el.id}}</h4>
                                <h5 class="card-title">{{el.title}}</h5>
                                <button type="button" data-bs-toggle="collapse" :data-bs-target="IdSelector(false,el.id)" class="btn btn-primary mb-2" aria-expanded="false" :aria-controls="IdSelector(true,el.id)">See content</button>
                                <br />
                                <p class="collapse" :id="IdSelector(true,el.id)">Last Response {{ new Date(el.updatedAt).toDateString()}}</p>
                                <p class="collapse display-6" :id="IdSelector(true,el.id)">Ticket Text:</p>
                                <p class="collapse"  :id="IdSelector(true,el.id)" v-for="(word, index) in el.text.split(/\n/)" :key="identific(word,el.id,index )">{{ word }}</p>
                                <router-link tag="a" class="btn btn-outline-primary collapse" :id="IdSelector(true,el.id)" :to="`/ticket/${el.id}`">Go Detail</router-link>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="ListTicket.ticket.length === 0">
                    <br />
                    <h6 class="subtitle">- You not have a open ticket -</h6>
                </template>
            </div>
        </template>
    </main>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: "ListViewTicket",
        data() {
            return {
                louder : false,
                err : false,
                selectId : 0,
                title : '',
                text : ''
            }
        },
        beforeMount(){
            this.Load();
        },
        computed: {
            ...mapGetters({
                ticket: 'ticket',
            }),
            ListTicket: function () {
                return this.ticket;
            }
        },
        methods : {
            ...mapActions({
                getTicketUser: 'getTicketUser',
            }),
            Load() {
                this.getTicketUser().then(() => {
                    this.louder = true
                });
            },
            selectEl(Id) {
                this.selectId = Id;
            },
            classSet(id) {
                if (this.selectId === id) {
                    return "active"
                }
            },
            IdSelector(bol,id) {
                if(!bol){
                    return `#selector${id}`;
                }
                else {
                    return `selector${id}`
                }

            },
            identific(id1, id2, id3){
                return `${id1}-${id2}-${id3}`
            },
            sendReq(){
                if(!this.title || !this.text){
                    this.err = true;
                }
                else{
                    this.$store.dispatch('postTicket', {title :this.title, text : this.text});
                    this.$notify({
                      title: 'Success Company',
                      type : 'success',
                      duration: 10000,
                      speed: 1000,
                      text: `Hi ${this.$store.getters.user.name}!\n You have Opend  New Ticket`
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
