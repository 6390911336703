<template>
    <main class="container">
        <template v-if="!louder" >
            <div class="align-items-center pt-5">
                <strong>Loading Page...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder">
            <h1 class="mt-2 display-4">Ticket #{{this.ticket.id}}</h1>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card shadow shadow-lg">
                        <div class="card-body">
                            <h4 class="display-5">{{ this.ticket.title}}</h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="col-lg-6 mt-2">
                    <div class="card shadow shadow-lg">
                        <div class="card-body">
                            <p>Create At</p>
                            <p>{{ new Date(this.ticket.createdAt).toDateString()}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6  mt-2">
                    <div class="card shadow shadow-lg">
                        <div class="card-body">
                            <p>Last Update At</p>
                            <p>{{ new Date(this.ticket.updatedAt).toDateString()}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div v-for="(section, index) in this.ticket.text.split('#######LAST#######')" :key="index" >
                    <div class="card shadow">
                        <div class="card-title">
                            #Response-{{index}}
                        </div>
                        <div class="card-body">
                            <p v-for="(word,index) in section.split(/\n/)" :key="identific(word,index)">{{ word }}</p>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
            <div class="row">
                <div class="card shadow shadow-lg m-2">
                    <div class="card-body">
                        <h3>Response a Ticket</h3>
                        <button class="btn btn-primary mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#sendform"  aria-expanded="false" aria-controls="sendform">Create a New Response</button>
                        <form id="sendform" class="collapse" @submit.prevent="sendReq">
                            <div class="mb-3">
                                <label for="TextInput" class="form-label">Text</label>
                                <textarea class="form-control" id="TextInput" rows="3" placeholder="My Response Ticket Text" v-model="text"></textarea>
                            </div >
                            <div class="mb-3" >
                                <button class="btn btn-outline-primary" type="submit">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </main>
</template>

<script>
    import * as ApiService from '@/helpers/api.service'
    export default {
        name: "detailTicket",
        data() {
            return {
                louder : false,
                err : false,
                selectId : 0,
                ticket : {},
                text : ''
            }
        },
        beforeMount(){
          this.load()
        },
        methods : {
          load(){
              ApiService.get(`/ticket/${this.paramKey}`).then((res) => {
                  if(res.data.message === "Ticket not Exist"){
                      this.$router.push('/404');
                  }
                  else if(!res.data.Ticket){
                      this.$router.push('/404');
                  }
                  else {
                      this.ticket = res.data.Ticket;
                      this.louder = true;
                  }
              })
          },
            identific(id1, id2){
              return `${id1}-${id2}`
            },
            sendReq(){
                ApiService.post(`/ticket/upd/`, { "id" : this.paramKey, "text" : this.text}).then((res) => {
                    if(res.data.messege === "Success Operation"){
                        this.louder = false;
                        this.load();
                    }
                })
            }
        },
        computed: {
            paramKey: function () {
                return this.$route.params.key
            },
        },
    }
</script>

<style scoped>

</style>