<template>
    <div>
        <div class="row mt-5">
            <div class="col-sm-12">
                <h2 class="text-center text-info">Ops..</h2>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-12">
                <h2 class="text-center text-info">Page Not Found</h2>
            </div>
        </div>
        <div class="row mb-5">
            <div class="center text-center col-sm-12">
                <h1 class="center text-danger display-1">404</h1>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "NotFound",
        beforeMount() {
          this.$notify({
            title: 'Not Found',
            type : 'error',
            duration: 10000,
            speed: 1000,
            text: `Hi ${this.$store.getters.user.name}!\n <br> the page you searched for is non-existent `
          });
        }
    }
</script>

<style scoped>

</style>