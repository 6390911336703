import { render, staticRenderFns } from "./detailTicket.vue?vue&type=template&id=78b2b63d&scoped=true&"
import script from "./detailTicket.vue?vue&type=script&lang=js&"
export * from "./detailTicket.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b2b63d",
  null
  
)

export default component.exports