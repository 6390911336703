<template>
        <main>
            <div class="container p-2">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title  "> Hi </h5>
                                <p class="card-text">Welcome to your Reset Password area</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <form @submit.prevent="onSubmit">
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">New Password</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" v-model="password">
                                    </div>
                                    <label for="inputPassword5" class="form-label">Confirm New Password</label>
                                    <input type="password" id="inputPassword5" class="form-control" aria-describedby="passwordHelpBlock" v-model="confirmPas">
                                    <div id="passwordHelpBlock" class="form-text">
                                        Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
                                    </div>
                                    <button type="submit" class="btn btn-primary" >
                                        <template v-if="send"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </template>
                                        Submit
                                    </button>
                                </form>
                            </div>
                            <template v-if="this.error">
                                <div class="alert alert-danger" role="alert">
                                    Attenction Error on Value
                                </div>
                            </template>
                            <template v-if="errorPasswordContent">
                                <div class="alert alert-danger" role="alert">
                                    Attention the password must contain at least 1 lowercase alphabetic character, at least 1 uppercase alphabetic character, at least 1 numeric character, at least one special character [! @ # $% ^ & *] E must be at least 8 characters long
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <br>
            </div>
        </main>
</template>

<script>
    import * as ApiService from "@/helpers/api.service";

    export default {
        name: "resetPassword",
        data() {
            return {
                History : [],
                louder : false,
                password : '',
                confirmPas : '',
                error : false,
                errorPasswordContent : false,
                send : false

            }
        },
        computed: {
            paramKey: function () {
                return this.$route.params.key
            },
        },
        methods : {
            onSubmit() {
                var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
                if (this.password === '' || this.confirmPas === ''){
                    this.error = true;
                }
                else if(this.password !== this.confirmPas){
                    this.error = true;
                }
                else if(!strongRegex.test(this.password)){
                    this.errorPasswordContent = true;
                }
                else {
                    this.error = false;
                    this.errorPasswordContent = false;
                    this.louder = true;
                    this.send = true;
                    const ID_TOKEN_KEY = process.env.VUE_APP_ID_TOKEN;
                    window.localStorage.removeItem(ID_TOKEN_KEY);
                    window.localStorage.setItem(ID_TOKEN_KEY, this.paramKey);
                    ApiService.init();
                    var obj = {
                        value : this.password,
                    };
                    ApiService.post(`/auth/change/`, obj ).then((res) => {

                            this.louder = false;
                            this.$router.push({ name: 'login' });

                    }).catch((err) =>{
                        this.louder = false;
                        this.error = false;
                    })

                }

            }

        }
    }
</script>

<style scoped>

</style>

