import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from 'vue-notification'



Vue.config.productionTip = false
Vue.use(Notifications);
// Vue.use(VueStripeElements, {
//   stripe: window.Stripe('pk_test_51IHaDdGkZgdPCt2VJQ1xSqZe6TwFExbXSj0vuMn91Vv9PBlUL5mNvz3bISKG5C8cxv1IxuXOiehOTeqguyknZcK800VcVg2HkQ'),
// });

Vue.component('search-vat', () => import('./components/search'));
Vue.component('add-card', () => import('./components/v2/AddCard'));
Vue.component('add-user-info', () => import('./components/v2/UserInfoForm'));
new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')

