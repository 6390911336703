<template>
    <div>
        <div class="row mt-5">
            <div class="col-sm-12">
                <h2 class="text-center text-info">Thanks You!</h2>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-12">
                <h2 class="text-center text-info">Go to home page</h2>
            </div>
        </div>
        <div class="row mb-5">
            <div class="center text-center col-sm-12">
                <h1 class="center text-success display-1">200</h1>
            </div>
        </div>
      <a type="button" class="btn btn-light btn-lg px-4 me-sm-3 mb-5" href="/">Go to Home</a>
      <a type="button" class="btn btn-light btn-lg px-4 me-sm-3 mb-5" href="/user/">Go to User</a>
    </div>
</template>


<script>
import * as ApiService from '@/helpers/api.service'
    export default {
        name: "Success",
      data () {
        return {
          planID : 0,
        }
      },
       async beforeMount() {
          if(this.setup_intent) {
            let url = `/auth/user/info/card`;
            await ApiService.post(url, { seti: this.setup_intent }).then((res) => {
              this.seti = res.data.key.client_secret;
            }).catch((err) =>{
              console.log(err)
            })
          }

          if(this.toFee === true){
            await this.order();
            //this.$router.push('/fee');
          }

          this.$notify({
            title: 'Success',
            type : 'Info',
            duration: 10000,
            speed: 1000,
            text: `Hi ${this.$store.getters.user.name}!\n <br> thanks you for Subscription on VatID.Cloud!`
          });
        },
      methods :{
        async order () {
          this.send = true;
          let url = `/auth/user/info/sub`;
          await ApiService.post(url, { planID : this.planID }).then((res) => {
            this.$notify({
              title: 'Success Subscription',
              type : 'success',
              duration: 10000,
              speed: 1000,
              text: `Hi!\n <br> thanks you for Subscription on VatID.Cloud`
            });
            this.$router.push('/');
          }).catch((err) =>{
            this.$notify({
              title: 'Error Subscription',
              type : 'error',
              duration: 10000,
              speed: 1000,
              text: `Error on Add Your Subscription <br /> Please try again later`
            });
            this.send = false;
            console.log(err);
          })
        },
      },
      computed : {
        setup_intent: function () {
          return this.$route.query.setup_intent
        },
        toFee: function () {
          return Boolean(this.$route.query.fee)
        },
      }
    }
</script>

<style scoped>

</style>
