<template>
    <div>
        <div class="mt-3 container">
            <form class="row g-3 mt-3" @submit.prevent="onSubmit">
                <h1 class="mb-5 mt-5">VatID</h1>
                <h2>Register User</h2>
                <template v-if="errorGen">
                    <div class="alert alert-danger" role="alert">
                        Attenction Error on Value
                    </div>
                </template>
                <template v-if="errorMail">
                    <div class="alert alert-danger" role="alert">
                        Attention the email is already present
                    </div>
                </template>
                <template v-if="errorNotMail">
                    <div class="alert alert-danger" role="alert">
                        Attention the email is not valid
                    </div>
                </template>
                <template v-if="errorPasswordContent">
                    <div class="alert alert-danger" role="alert">
                        Attention the password must contain at least 1 lowercase alphabetic character, at least 1 uppercase alphabetic character, at least 1 numeric character, at least one special character [! @ # $% ^ & *] E must be at least 8 characters long
                    </div>
                </template>
                <template v-if="errorConfrimPassowrd">
                    <div class="alert alert-danger" role="alert">
                        Attention the Confirm Password is not valid
                    </div>
                </template>
                <div class="col-md-6">
                    <label for="Name" class="form-label">Name</label>
                    <input type="text" class="form-control" id="Name" placeholder="Your Name" v-model="names">
                </div>
                <div class="col-md-6">
                    <label for="Surname" class="form-label">Surname</label>
                    <input type="text" class="form-control" id="Surname" placeholder="Your Surname" v-model="surname">
                </div>
                <div class="col-md-6">
                    <label for="inputEmail4" class="form-label">Email</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="YourMail@VatID.Cloud" v-model="email">
                </div>
                <div class="col-md-6">
                    <label for="inputPassword4" class="form-label">Password</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="Your Password" v-model="password">
                </div>
                <div class="col-md-12">
                    <label for="inputPassword5" class="form-label">Confirm Password</label>
                    <input type="password" class="form-control" id="inputPassword5" placeholder="Confirm Password" v-model="confirmPassword">
                </div>
                <div class="form-check col-md-12 text-start ">
                    <input class="form-check-input " type="checkbox" value="" id="flexCheckIndeterminate" v-model="accempt">
                    <label class="form-check-label" for="flexCheckIndeterminate">
                        I accept terms and conditions required to access the sevices by VatID.Cloud - <button class="btn btn-outline-primary btn-md" @click.prevent="viewsTerms">View terms and conditions</button>
                    </label>
                </div>
              <div class="form-check col-md-12 text-start ">
                <input class="form-check-input " type="checkbox" value="" id="flexCheckIndeterminate" v-model="privacyAccept">
                <label class="form-check-label" for="flexCheckIndeterminate">
                  I accept privacy policy required to access the sevices by VatID.Cloud - <button class="btn btn-outline-primary btn-md" @click.prevent="viewsPolicy">View Privacy Policy</button>
                </label>
              </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-outline-primary bg-lg " v-bind:disabled="!accempt || !privacyAccept">
                        <template v-if="send"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </template>
                        Register
                    </button>
                </div>
            </form>
            <br />
            <router-link  :to="loginReturnPage" tag="a" class="btn  btn-outline-info btn-sm">You already have an account? go to Login</router-link>
            <br />
            <p class="mt-5 mb-3 text-muted">© 2020 - {{ new Date().getFullYear() }} - @VatID.Cloud - Europe</p>
        </div>
    </div>
</template>

<script>
    import * as ApiService from '@/helpers/api.service'
    export default {
        name: "register",
        data () {
            return {
                names : '',
                surname : '',
                email : '',
                password : '',
                confirmPassword : '',
                send : false,
                accempt: false,
                privacyAccept : false,
                errorMail : false,
                errorNotMail : false,
                errorGen : false,
                errorConfrimPassowrd : false,
                errorPasswordContent : false
            }
        },
        methods : {
           async onSubmit (){
               this.send = true;
               this.errorConfrimPassowrd = false;
               this.errorPasswordContent = false;
               this.errorMail = false;
               this.errorGen = false;
                this.errorNotMail = false;
                var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
               var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
                if( this.email.length === 0 || this.names.length === 0  || this.surname.length === 0  || this.password.length === 0 || this.confirmPassword.length === 0 ){
                    this.errorGen = true;
                    this.send = false;
                }
                else if(!mailformat.test(this.email)){
                    this.errorNotMail = true;
                }
                else {
                   await ApiService.post('/auth/user/test', {email : this.email }).then(async (result) => {
                        this.errorMail = result.data.exist;
                        if(this.confirmPassword !== this.password){
                            this.errorConfrimPassowrd = true;
                            this.send = false;
                        }
                        else if (this.errorMail){
                            this.send = false;
                        }
                        else {
                            if(strongRegex.test(this.password)){
                                if(this.errorGen=== false && this.errorMail === false &&  this.errorPasswordContent === false && this.errorConfrimPassowrd === false ){
                                    const formData = {
                                        email : this.email,
                                        name : this.names,
                                        surname : this.surname,
                                        password: this.password
                                    };

                                    let goto =  this.returnPage;
                                    await this.$store.dispatch('register', {email : formData.email, password : formData.password, name : formData.name, surname : formData.surname});
                                    await this.$store.dispatch('login', {email : formData.email, password : formData.password, returnPage : goto});
                                }
                                else {
                                    this.send = false;
                                }
                            }
                            else {
                                this.send = false;
                                this.errorPasswordContent = true;
                            }
                        }
                    });

                }

            },
          viewsTerms () {
            window.open(`https://www.iubenda.com/terms-and-conditions/14075729`, "terms", 'location=yes,height=570,width=520,scrollbars=yes,status=yes"')
          },
          viewsPolicy () {
            window.open(`https://www.iubenda.com/privacy-policy/14075729`, "policy", 'location=yes,height=570,width=520,scrollbars=yes,status=yes"')
          }
        },
      computed : {
        returnPage: function () {
          return this.$route.query.returnPage || "/"
        },
        loginReturnPage : function () {
          return `/login?returnPage=${this.returnPage}`  || "/login"
        },
      }
    }
</script>

<style scoped>

</style>
