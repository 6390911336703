<template>
    <main>
        <template v-if="!louder" >
            <div class="align-items-center pt-5">
                <strong>Loading Page...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder">

            <div class="row">
                <div class="col col-lg-4 card shadow shadow-lg m-2">
                    <div class="card-body">
                        <h5> Number Users {{ListUsers.data.numberUser }}</h5>
                        <div class="list-group">
                            <a href="#" class="list-group-item list-group-item-action" v-bind:class="classSet(index)" aria-current="true" v-for="(el, index) in ListUsers.data.Users" :key="el.id" @click.prevent="selectEl(index)">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ el.name }} {{ el.surname }}</h5>
                                    <small>#{{ el.id }}</small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6 card shadow shadow-lg m-2">
                    <div class="card-body">
                        <h5 class="card-title">{{ ListUsers.data.Users[selectId].name }} {{ ListUsers.data.Users[selectId].surname }}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{{ ListUsers.data.Users[selectId].email }}</h6>
                        <p class="card-text">Subscribe At {{ new Date(ListUsers.data.Users[selectId].createdAt).toDateString() }}</p>
                        <!-- <p class="card-text">Last Login {{ new Date(ListUsers.data.Users[selectId].LoginLogs[ListUsers.data.Users[selectId].LoginLogs.length-1].createdAt).toDateString()}}</p> -->
                        <!-- <p class="card-text">Value Of By Credit {{ ListUsers.data.Users[selectId].Credits.reduce(function(acc, val) { return acc + val.value; }, 0)}}</p> -->
                        <button class="btn btn-outline-dark btn-lg" @click.prevent="RstPsw(ListUsers.data.Users[selectId].id)">Req Reset Password</button>
                        <button class="btn btn-outline-primary m-2 btn-lg" type="button" data-bs-toggle="collapse" data-bs-target="#sendform"  aria-expanded="false" aria-controls="sendform">Add Credit to User</button>
                        <form id="sendform" class="collapse" @submit.prevent="AddCredit">
                            <div class="mb-3">
                                <label for="titleinput" class="form-label">Motivation</label>
                                <input type="text" class="form-control" id="titleinput" placeholder="Motivation For Add Credit" v-model="motivation">
                            </div>
                            <div class="mb-3">
                                <label for="TextInput" class="form-label">Value</label>
                                <input type="text" class="form-control" id="TextInput" placeholder="Value Add Credit" v-model="value">
                            </div >
                            <div class="mb-3" >
                                <button class="btn btn-outline-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </template>
    </main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: "users",
       data() {
            return {
                louder : false,
                err : false,
                selectId : 0,
                motivation: '',
                value : '',
                ListUsers : {
                    data : {
                        numberUser : 0,
                        Users : []
                    }
                }
            }
       },
        beforeMount(){
          this.Load();
        },
        computed: {
            ...mapGetters({
                getUsersAdmin: 'Admin/getUsersAdmin',
            }),
            //ListUsers : function () {
              //  return this.getUsersAdmin;
            //}
        },
        methods : {
            ...mapActions({
                fetchUserAll : 'Admin/fetchUserAll',
                fetchReqRSTPSWForUser : 'Admin/fetchReqRSTPSWForUser',
                fetchAddCreditforUser : 'Admin/fetchAddCreditforUser'
            }),
            Load () {
                this.fetchUserAll().then(() => { this.louder = true });
                this.ListUsers = this.getUsersAdmin;
            },
            selectEl (Id) {
                this.selectId = Id;
            },
            classSet (id){
                if(this.selectId === id){
                    return "active"
                }
            },
            RstPsw (id) {
                this.fetchReqRSTPSWForUser(id);
            },
            AddCredit(){
                this.fetchAddCreditforUser({motivation: this.motivation, userid : this.ListUsers.data.Users[this.selectId].id, value: this.value});
                this.$notify({
                  title: 'Success Company',
                  type : 'success',
                  duration: 10000,
                  speed: 1000,
                  text: `Hi ${this.$store.getters.user.name}!\n <br> Add ${this.value} Credit a ${this.ListUsers.data.Users[this.selectId].name} ${this.ListUsers.data.Users[this.selectId].surname}`
                });

            }

        }
    }
</script>

<style scoped>

</style>