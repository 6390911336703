import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import register from "@/components/auth/register";
import login from "@/components/auth/login";
import about from "@/views/About"
import store from '@/store';
import Credit from "@/components/Credit";
import History from "@/components/History";
import search from "@/components/search";
import Company from "@/components/CompanyMasterData";
import sendVatID from "@/components/sendVatID";
import NotFound from "@/views/NotFound";
import searchByVat from "@/components/searchByVat";
import charge from "@/components/charge";
import NotCredit from "@/views/NotCredit";
import UserDate from "@/components/UserDate";
import updateUser from "@/components/updateUser";
import resetPassword from "@/components/auth/resetPassword";
import NotAuthorizate from "@/views/NotAuthorizate";
import Success from "@/views/ReturnStripe";
import dashboard from "@/components/admin/dashboard";
import users from "@/components/admin/users";
import query from "@/components/admin/query";
import CompanyClassification from "@/components/admin/CompanyClassification";
import ListViewTicket from "@/components/ticket/ListViewTicket";
import detailTicket from "@/components/ticket/detailTicket";
import NewHome from "@/views/NewHome";
import UserHome from "@/components/v2/UserHome";
import Fee from "@/components/v2/Fee";
import UserInfoForm from "@/components/v2/UserInfoForm";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/Old',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'NewHome',
    component: NewHome,
    beforeEnter(to, from, next) {
      if (process.env.VUE_APP_BETA_STATE === "true") {
        next('/login');
      } else {
        next();
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    beforeEnter(to, from, next) {
      if (process.env.VUE_APP_BETA_STATE === "true") {
        next('/login');
      } else {
       next();
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: about,
  //   beforeEnter(to, from, next) {
  //     if (store.getters.isAuth) {
  //       next();
  //     } else {
  //       next('/login')
  //     }
  //   }
  // },
  {
    path: '/user',
    name: 'User',
    component: UserHome,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login?returnPage=/user/')
      }
    }
  },
  {
    path: '/user/info',
    name: 'UserInfo',
    component: UserInfoForm,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login?returnPage=/user/')
      }
    }
  },
  {
    path: '/user/update/:key',
    name: 'updateUser',
    component: updateUser,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/user/changepassword/:key',
    name: 'resetPassword',
    component: resetPassword,
  },
  {
    path: '/ticket',
    name: 'ListTicket',
    component: ListViewTicket,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/ticket/:key',
    name: 'DetailTicket',
    component: detailTicket,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login')
      }
    }
  },
  // {
  //   path: '/credit',
  //   name: 'Credit',
  //   component: Credit,
  //   beforeEnter(to, from, next) {
  //     if (store.getters.isAuth) {
  //       next();
  //     } else {
  //       next('/login')
  //     }
  //   }
  // },
  {
    path: '/history',
    name: 'History',
    component: History,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login?returnPage=/history/')
      }
    }
  },
  {
    path: '/fee',
    name: 'Fee',
    component: Fee,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login?returnPage=/fee/')
      }
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: search,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/searchByVat',
    name: 'SearchByVat',
    component: searchByVat,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/company/:key',
    name: 'CompanyData',
    component: Company,
    beforeEnter(to, from, next) {
      const key = to.params.key;
      if (store.getters.isAuth) {
        next();
      } else {
        next(`/login?returnPage=/company/${key}`);
      }
    }
  },
  {
    path: '/sendVat',
    name: 'sendVat',
    component: sendVatID,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        next();
      } else {
        next('/login')
      }
    }
  },
  // {
  //   path: '/charge',
  //   name: 'Charge',
  //   component: charge,
  //   beforeEnter(to, from, next) {
  //     if (store.getters.isAuth) {
  //       next();
  //     } else {
  //       next('/login')
  //     }
  //   }
  // },
  // {
  //   path: '/checkout/:key',
  //   name: 'Checkout',
  //   component: checkout,
  //   beforeEnter(to, from, next) {
  //     if (store.getters.isAuth) {
  //       next();
  //     } else {
  //       next('/login')
  //     }
  //   }
  // },
  // {
  //   path: '/successPay',
  //   name: 'SuccessPay',
  //   component: successPay,
  // },
  // {
  //   path: '/cancelPay',
  //   name: 'CancelPay',
  //   component: cancelPay,
  // },
  {
    path: '/admin',
    name: 'Admin',
    component: dashboard,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        if(store.getters.isAdmin.IsAd) {
          next();
        }
        else {
          next('/403');
        }
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: users,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        if(store.getters.isAdmin.IsAd) {
          next();
        }
        else {
          next('/403');
        }
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/admin/query',
    name: 'AdminQuery',
    component: query,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        if(store.getters.isAdmin.IsAd) {
          next();
        }
        else {
          next('/403');
        }
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/admin/comapanyclassification',
    name: 'AdminComapanyClassification',
    component: CompanyClassification,
    beforeEnter(to, from, next) {
      if (store.getters.isAuth) {
        if(store.getters.isAdmin.IsAd) {
          next();
        }
        else {
          next('/403');
        }
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/401',
    name : 'NotCredit',
    component : NotCredit
  },
  {
    path:'/403',
    name : 'NotAuth',
    component: NotAuthorizate
  },
  {
    path:'/success',
    name : 'Success',
    component: Success
  },
  {
    path: '*',
    name : 'NotFound',
    component : NotFound
  }
];

const router = new VueRouter({
  mode : 'history',
  routes
});

export default router
