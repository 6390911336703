import Vue from 'vue'
//import { sleep } from '@/lib/util'
//import { postJsonUno, postJsonDue, postJsonTre } from '@/jsonresponse'
import * as ApiSerivice from "@/helpers/api.service";
const state = {
    credits: []
}
const getters = {
    getCredits (state) {
        return state.credits
    },
    getCredit: (state, getters) => (paramkey) => {
        if (getters.getCredits) {
            const funf = getters.getCredits.find(el => { return el.key === paramkey })
            if (funf) {
                return funf
            } else {
                return null
            }
        }
        return null
    }
}
const actions = {
   async fetchCredit ({state, commit, dispatch}, payload) {
       await ApiSerivice.get('/credit/').then((res) => {
            commit('setCredits', { credits:{ history: res.data.data.Credits, disponiblecredit : res.data.Disponible_Credit, viewhistory:  res.data.history, billingInfo : res.data.data.BillingInfo } });
        }).catch((err) => { });
    }
}
const mutations = {
    setCredits (state, payload) {
        state.credits = payload
    },
    setCredit (state, payload) {
        Vue.set(state.credits, payload.key, payload)
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
