<template>
    <div class="container mt-5">
        <h1 class="title">View History</h1>
        <template v-if="!louder">
            <div class="d-flex align-items-center">
                <strong>Loading...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder" class="text-center">
            <div class="row">
                <div class="card col-lg-12 center">
                    <div class="card-body">
                        <h5 class="card-title">Your Have Visited</h5>
                        <h6 class="card-subtitle mb-2 text-muted">of Today</h6>
                        <p class="card-text" >View : {{ this.Credits.credits.viewhistory.length }} Companys</p>
<!--                        <router-link tag="a" to="/charge" class="card-link btn btn-outline-primary btn-sm"><i class="fad fa-charging-station"></i> Credit Recharge</router-link>-->
<!--                        <router-link tag="a" to="/sendVat" class="card-link btn btn-outline-primary btn-sm"> <i class="fad fa-battery-bolt"></i> Free Credit Recharge</router-link>-->
                    </div>
                </div>
            </div>
            <div class="row mt-2 mb-2">
                <h5>Filter your List View</h5>
                <div class=" mb-3 ">
                    <h6>Select filter</h6>
                    <select class="form-select" id="inputGroupSelect02" v-model="set_search">
                        <option selected value="0">Choose...</option>
                        <option value="1">Name Company</option>
                        <option value="2">Country</option>
                    </select>
                </div>
                <div class="mb-3" v-if="set_search == 1">
                    <label for="filterlist" class="form-label">Search by Names Company</label>
                    <input type="text" class="form-control" id="filterlist" placeholder="Name Company" v-model="search">
                </div>
                <div class="mb-3" v-if="set_search == 2">
                    <label for="exampleDataList" class="form-label">Search by State Company</label>
                    <input class="form-control" list="countries" id="exampleDataList" placeholder="EU Europe" v-model="state_search">
                    <datalist id="countries">
                        <option v-for="state in countries "> {{ state.id }} {{ state.verboseName }}</option>
                    </datalist>
                </div>
            </div>
            <div class="center text-center d-flex justify-content-center">
                <div class="row align-items-center">
                    <div class="card col-lg-12 mt-2" v-for="el in filterCompany" :key = "el.id">
                        <div class="card-body" >
                            <h4 class="card-title"><span v-bind:class="Flag(el.companyId.countryCode)"></span></h4>
                            <h5 class="card-title">{{ el.companyId.name.toUpperCase() }}</h5>
                            <h6 class="card-subtitle mb-2 text-muted">{{ el.companyId.countryCode }}{{ el.companyId.vatNumber }}</h6>
                            <p class="card-text">{{ el.companyId.address }}</p>
                            <router-link tag="a" class="btn btn-outline-primary" :to="`/company/${el.companyId.id}`">View</router-link>
                            <p class="card-text text-muted mt-1">First View {{ new Date(el.createdAt).toDateString() }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as GetISO from "@/helpers/convertToNationISO";
    export default {
        name: "Credit",
        data() {
            return {
                valureCredit : 0,
                History : [],
                louder : false,
                search : '',
                state_search : '',
                set_search : '',
                flagclass : 'flag-icon flag-icon-',
                countries : [
                    {id:"AT", verboseName:"Austria"},
                    {id:"BE", verboseName:"Belgium"},
                    {id:"BG", verboseName:"Bulgaria"},
                    {id:"CY", verboseName:"Cyprus"},
                    {id:"CZ", verboseName:"Czech Republic"},
                    {id:"DE", verboseName:"Germany"},
                    {id:"DK", verboseName:"Denmark"},
                    {id:"EE", verboseName:"Estonia"},
                    {id:"EL", verboseName:"Greece"},
                    {id:"ES", verboseName:"Spain"},
                    {id:"FI" , verboseName:"Finland"},
                    {id:"FR", verboseName:"France"},
                    {id:"GB", verboseName:"United Kingdom"},
                    {id:"HR", verboseName:"Croatia"},
                    {id:"HU", verboseName:"Hungary"},
                    {id:"IE", verboseName:"Ireland"},
                    {id:"IT", verboseName:"Italy"},
                    {id:"LT", verboseName:"Lithuania"},
                    {id:"LU", verboseName:"Luxembourg"},
                    {id:"LV", verboseName:"Latvia"},
                    {id:"MT", verboseName:"Malta"},
                    {id:"NL", verboseName:"The Netherlands"},
                    {id:"PL", verboseName:"Poland"},
                    {id:"PT", verboseName:"Portugal"},
                    {id:"RO", verboseName:"Romania"},
                    {id:"SE", verboseName:"Sweden"},
                    {id:"SI", verboseName:"Slovenia"},
                    {id:"SK", verboseName:"Slovakia"},
                ]
            }
        },
        async beforeMount() {
          await this.Load();
        },
        computed: {
            Credits: function () {
              return this.getCredits
            },
          ...mapGetters({
              getCredits : 'Credit/getCredits'
          }),
            filterCompany () {
              return this.Credits.credits.viewhistory.slice().reverse().filter((el) => {
                  if( this.set_search == 1) {
                      return el.companyId.name.toUpperCase().match(this.search.toUpperCase());
                  }
                  else if (this.set_search == 2){
                      return el.companyId.countryCode.match(`${this.state_search[0]}${this.state_search[1]}`);
                  }
                  else {
                      return el;
                  }
              })
            },
        },
        methods: {
            ...mapActions({
                fetchCredit: 'Credit/fetchCredit'
            }),
            async Load () {
                await this.fetchCredit().then(() => { this.louder = true});

            },
            Flag(countryCode) {
                return this.flagclass+GetISO.GetISoCodeNation(countryCode).toLowerCase();
            },
        }
    }
</script>

<style scoped>


</style>
