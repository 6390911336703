<template>
    <div>
        <div class="container text-center">
            <form class="form-signin"  @submit.prevent="onSubmit">
                <h1 class="mb-5 mt-5">VatID</h1>
                <h3 class="h3 mb-3 fw-normal">Sign In</h3>
                <template v-if="this.$store.getters.isErr">
                    <div class="alert alert-danger" role="alert">
                        Attenction Error on Mail or Password
                    </div>
                </template>
                <div class="col-md-12">
                    <label for="inputEmail4" class="form-label">Email</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="YourName@Email" v-model="email">
                </div>
                <div class="col-md-12">
                    <label for="inputPassword4" class="form-label">Password</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="Your Password" v-model="password">
                </div>
                <div class="col-12 mt-4">
                    <button type="submit" class="btn btn-outline-primary btn-lg">
                        <template v-if="send"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </template>
                        Sign in
                    </button>
                </div>
                <br />
                <router-link  :to="registerReturnPage" tag="a" class="btn  btn-outline-info btn-sm">You not have an account? go to Register</router-link>
                <p class="mt-5 mb-3 text-muted">© 2020 - {{ new Date().getFullYear() }} - @VatID.Cloud - Europe</p>
            </form>
        </div>
    </div>
</template>

<script>


    export default {
        name: "login",
        data () {
            return {
                email : '',
                password : '',
                send : false,
                err: false
            }
        },
        methods : {
            async onSubmit(){
                this.err = false;
                this.send = true;
                const formData = {
                    email : this.email,
                    password: this.password
                };
               let goto =  this.returnPage;
              await this.$store.dispatch('login', {email : formData.email, password : formData.password, returnPage : goto});
            }
        },
      computed : {
        returnPage: function () {
          return this.$route.query.returnPage || "/"
        },
        registerReturnPage : function () {
          return `/register?returnPage=${this.returnPage}`  || "/register"
        },
      }
    }
</script>

<style scoped>

</style>
