<template>
    <main class="container p-2">
        <div class="row">
            <div class="col-sm-12">
                <template v-if="pasw">
                    <div class="card">
                        <div class="card-body shadow rounded">
                            <h5 class="card-title display-3 "> Hi {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }} </h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text display-5">Request Reset Your Password </p>
                            <form @submit.prevent="onSubmit">
                                <button class="btn btn-lg btn-outline-warning">
                                    <template v-if="send"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </template>
                                    Request Reset
                                </button>
                                <p class="card-text"> you will receive a link to reset your password to the email you registered with</p>
                            </form>
                        </div>
                    </div>
                </template>
               <template v-if="!pasw">
                   <div class="card">
                       <div class="card-body shadow rounded">
                           <h5 class="card-title display-3 "> Hi {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }} </h5>
                           <h6 class="card-subtitle mb-2 text-muted"></h6>
                           <p class="card-text display-5">Your New  {{this.paramKey}} is </p>
                           <form @submit.prevent="onSubmit">
                               <input class="form-control form-control-lg mb-4" type="text" placeholder="Insert a your new Value" aria-label="New Value" v-model="dataValue">
                               <button class="btn btn-lg btn-outline-warning">
                                   <template v-if="send"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </template>
                                   Update Your Data
                               </button>
                           </form>
                       </div>
                   </div>
               </template>
            </div>
            <template v-if="this.error">
                <div class="alert alert-danger" role="alert">
                    Attenction Error on Value
                </div>
            </template>
        </div>
    </main>
</template>

<script>
    import * as ApiService from '@/helpers/api.service'
    export default {
        name: "updateUser",
        data() {
            return {
                dataValue : '',
                error : false,
                louder : false,
                send : false,
                pasw : false
            }
        },
        beforeMount() {
            this.isPasw();
        },
        computed: {
            paramKey: function () {
                return this.$route.params.key
            },


        },
        methods : {
            isPasw(){
               if ( this.$route.params.key === 'psw'){
                   this.pasw = true;
               }
               else {
                   this.pasw = false;
               }

            } ,
            onSubmit() {
                if (this.pasw) {
                    this.error = false;
                    this.louder = true;
                    this.send = true;
                    var obj = {
                        key : this.paramKey,
                        value : this.dataValue,
                    };
                    ApiService.post(`/auth/user`, obj ).then((res) => {
                            this.louder = false;
                            //console.log('SUCCESS');
                            this.send = false;
                        this.$notify({
                          title: 'Update User Data',
                          type : 'info',
                          duration: 10000,
                          speed: 1000,
                          text: `Hi ${this.$store.getters.user.name}!\n we sent you an email, please follow the link to complete the update`
                        });
                        this.$router.push({ name: 'User' });
                    }).catch((err) =>{
                        this.louder = false;
                        this.error = false;
                        //this.louder = false;
                        //console.log(err);
                    });
                }
                else {
                    if (this.dataValue === ''){
                        this.error = true;
                    }else {
                        this.error = false;
                        this.louder = true;
                        this.send = true;
                        var obj = {
                            key : this.paramKey,
                            value : this.dataValue,
                        };
                        ApiService.post(`/auth/user`, obj ).then((res) => {
                            if(res.statusCode() === 200) {
                                this.louder = false;
                                this.$notify({
                                  title: 'Update User Data',
                                  type : 'info',
                                  duration: 10000,
                                  speed: 1000,
                                  text: `Hi ${this.$store.getters.user.name},\n we have updated your data`
                                });
                                this.$route.push('/');
                            }
                            else {
                                this.louder = false;
                                this.error = false;
                            }

                        }).catch((err) =>{
                            this.louder = false;
                            this.error = false;
                            //this.louder = false;
                            //console.log(err);
                        })

                    }
                }

            }

        }
    }
</script>

<style scoped>

</style>