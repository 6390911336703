<template>
    <main>
        <template v-if="!louder" class="container p-2">
            <div class="align-items-center pt-5">
                <strong>Loading...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </template>
        <template v-if="louder">
            <div class="container p-2">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title display-3 "> Hi {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }} </h5>
                                <h6 class="card-subtitle mb-2 text-muted">{{ this.$store.state.user.email }}</h6>
                                <p class="card-text">Welcome to your dedicated area</p>
                                <router-link  to="/history" tag="a" class="card-link btn btn-outline-primary btn-lg"><i class="fad fa-history"></i> View Your History</router-link>
                                <router-link  to="/credit" tag="a" class="card-link btn btn-outline-primary btn-lg"><i class="fad fa-credit-card"></i> View Your Credit</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title"> You have Visited </h5>
                                <h6 class="card-subtitle mb-2 text-muted"></h6>
                                <p class="card-text display-3"><i class="fad fa-building"></i> {{ Credits.credits.history.length}} Company </p>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title"> You have Credit </h5>
                                <h6 class="card-subtitle mb-2 text-muted"></h6>
                                <p class="card-text display-3"><i class="fad fa-coins"></i> {{ Credits.credits.disponiblecredit }} Credits </p>
                            </div>
                        </div>
                    </div>
                </div>
              <br>
              <invoice-date></invoice-date>
                <br>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body shadow rounded">
                                <h5 class="card-title"> You need to update your data ?  </h5>
                                <h6 class="card-subtitle mb-2 text-muted">select option</h6>
                                <p class="card-text">for go to update page</p>
                                <router-link  to="/user/update/name" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-signature"></i> Update Your Name</router-link>
                                <router-link  to="/user/update/surname" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-file-signature"></i> Update Your Surname</router-link>
                                <router-link  to="/user/update/email" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-envelope"></i> Update Your Email</router-link>
                                <router-link  to="/user/update/psw" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-key"></i> Update Your Password</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

            </div>
        </template>

    </main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as ApiService from '@/helpers/api.service'
    import InvoiceDate from "@/components/invoiceDate";
    export default {
        name: "UserDate",
      components: {InvoiceDate},
      data() {
            return {
                valureCredit : 0,
                History : [],
                louder : false,
                modifystate : false,
                send : false,

            }
        },

        beforeMount() {
            this.Load();
        },
        computed: {
            Credits: function () {
                return this.getCredits
            },
            ...mapGetters({
                getCredits : 'Credit/getCredits',
            })
        },
        methods: {
            ...mapActions({
                fetchCredit: 'Credit/fetchCredit',

            }),
            Load () {
                this.fetchCredit().then(() => { this.louder = true});

            },

        }

    }
</script>

<style scoped>

</style>
