<template>
    <main>
        <div class="container">
            <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label">Query</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="SELECT * FROM" v-model="queryText"></textarea>
            </div>
            <button class="btn btn-lg btn-outline-dark" @click.prevent="Load"> Send Query</button>
        </div>
        <br />
        <div class="alert alert-danger" role="alert" v-if="err">
           Attection on Query Exist Error!
        </div>
    <template v-if="submit">
        <br />
        <div v-if="!louder">
            <div class="align-items-center pt-5">
                <strong>Loading Result...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
        </div>
        <div v-if="louder">
            <div class="table-responsive-md">
                <table class="table table-hover">
                    <thead class="table-dark">
                    <tr>
                        <th scope="col" v-for="(el, index) in IntexTableResult" :key="index">
                            {{ el }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(el, indext) in ListResult" :key="indext">
                        <template v-for="els in IntexTableResult">
                            <td>{{ el[els]}}</td>
                        </template>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </template>
    </main>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        name: "query",
        data(){
            return {
                queryText : '',
                louder : true,
                submit : false,
                err : false,
                IntexTableResult : []
            }
        },
        computed: {
            ...mapGetters({
                getReusltAdmin: 'Admin/getReusltAdmin',
            }),
            ListResult : function () {
                return this.getReusltAdmin.isEmpty ? 0 :  this.getReusltAdmin;
            }
        },
        methods : {
            ...mapActions({
                fetchQuery : 'Admin/fetchQuery'
            }),
            KewForQuery() {
                 for(let nameKey in  this.ListResult[0] ){
                    this.IntexTableResult.push(nameKey);
                 }
            },
            Load () {
                this.err = false;
                this.submit = true;
                this.louder = false;

                this.fetchQuery(this.queryText).then(() => { }).catch((err) => {
                    console.log(err);
                    this.err = true;
                });
                this.KewForQuery();
                this.louder = true
            }
        }
    }
</script>

<style scoped>

</style>
