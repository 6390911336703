<template>
    <div class="container mt-4">
        <h2>Search by VatID</h2>
        <div class="row center text-center mt-5">
            <div class="">
                <form @submit.prevent="onSubmit" >
                    <div class="input-group mb-3 row center" >
                        <label for="input" class="col-lg-2 col-form-label col-form-label-lg">Company VatId</label>
                        <input id="input" type="text" class="form-control form-control-lg col-lg-8" placeholder="IT012348567081" aria-label="VatID Research" aria-describedby="button-addon2" v-model="text">
                        <button class="btn btn-outline-primary col-lg-2" type="submit" id="button-addon2">Send</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center">
            <div class="alert alert-danger" role="alert" v-if="error">
                Attection content not valid!
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center" v-if="resech">

            <template v-if="louder">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </template>
            <template v-if="!louder">
              <template>
                  <div class="card  m-1 col-md-3" >
                      <div class="card-body">
                          <p class="text-muted"><span class="flag-icon" v-bind:class="classFlag"></span> {{ company.countryCode}}</p>
                          <h5 class="card-title">{{ company.name}}</h5>
                          <p class="card-text">{{ company.address}}</p>
                          <router-link tag="a" class="btn btn-primary" :to="`/company/${company.id}`">View</router-link>
                      </div>
                  </div>
              </template>
                <template  v-if="company.length >= 0">
                    <h6 class="subtitle"> No other Record Result for this Research</h6>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import * as ApiService from '@/helpers/api.service'
    import * as GetISO from "@/helpers/convertToNationISO";
    export default {
        name: "searchByVat",
        data() {
            return  {
                louder : false,
                resech : false,
                text:'',
                error : false,
                company: {},
                classFlag : 'flag-icon-',
                countries : [
                  {id:"AT", verboseName:"Austria", minChar : 10, name : "UID - Umsatzsteuer-Identifikationsnummer", example :"ATU12345678"},
                  {id:"BE", verboseName:"Belgium", minChar : 12, name : "BTW-nr - BTW identificatienummer", example :"BE01234567XX"},
                  {id:"BG", verboseName:"Bulgaria", minChar : 9, name : "ДДС номер - Идентификационен номер по ДДС Identifikacionen nomer po DDS", example :"BG01234567890"},
                  {id:"CY", verboseName:"Cyprus", minChar : 9, name : "ΦΠΑ - Αριθμός Εγγραφής Φ.Π.Α.", example :"CY12345678L"},
                  {id:"CZ", verboseName:"Czech Republic",  minChar : 10, name : "DIČ - Daňové identifikační číslo", example :"CZ123457978"},
                  {id:"DE", verboseName:"Germany",  minChar : 11, name : " USt-IdNr - Umsatzsteuer-Identifikationsnummer", example :"DE123456789"},
                  {id:"DK", verboseName:"Denmark" ,  minChar : 10, name : "CVR - Momsregistreringsnummer", example :"DK12345678"},
                  {id:"EE", verboseName:"Estonia", minChar : 11, name : "KMKR - Käibemaksukohustuslase number", example :"EE123456789"},
                  {id:"EL", verboseName:"Greece", minChar : 11, name : "ΑΦΜ - Arithmós Forologikou Mētrṓou", example :"EL123456789"},
                  {id:"ES", verboseName:"Spain", minChar : 11, name : "NIF - Número de Identificación Fiscal", example :"ESX1234567R"},
                  {id:"FI" , verboseName:"Finland", minChar : 9, name : "ALV nro Momsnummer - Arvonlisäveronumero Mervärdesskattenummer", example :"FI12348975"},
                  {id:"FR", verboseName:"France", minChar : 10, name : "ALV nro Momsnummer - Arvonlisäveronumero Mervärdesskattenummer", example :"FR35123456789"},
                  {id:"GB", verboseName:"United Kingdom"},
                  {id:"HR", verboseName:"Croatia", minChar : 12, name : "PDV-ID OIB - PDV Id. Broj OIB", example :"HR12345678901"} ,
                  {id:"HU", verboseName:"Hungary", minChar : 10, name : "ANUM - Közösségi adószám", example :"HU12345678"},
                  {id:"IE", verboseName:"Ireland", minChar : 10, name : "VAT - Value added tax identification", example :"IE1234567FA"},
                  {id:"IT", verboseName:"Italy", minChar : 11, name : "Partita IVA", example :"IT1234578901"},
                  {id:"LT", verboseName:"Lithuania", minChar : 9, name : "PVM kodas - Pridėtinės vertės mokestis mokėtojo kodas", example :"LT123456789012"},
                  {id:"LU", verboseName:"Luxembourg", minChar : 9, name : "PVM kodas - Pridėtinės vertės mokestis mokėtojo kodas", example :"LT123456789012"},
                  {id:"LV", verboseName:"Latvia" , minChar : 13, name : "PVN - Pievienotās vērtības nodokļa reģistrācijas numurs", example :"LV12345678901"},
                  {id:"MT", verboseName:"Malta"},
                  {id:"NL", verboseName:"The Netherlands"},
                  {id:"PL", verboseName:"Poland"},
                  {id:"PT", verboseName:"Portugal"},
                  {id:"RO", verboseName:"Romania"},
                  {id:"SE", verboseName:"Sweden"},
                  {id:"SI", verboseName:"Slovenia"},
                  {id:"SK", verboseName:"Slovakia"},
                ]
            }
        },
        computed : {
            flag: function () {
                return GetISO.GetISoCodeNation(this.company.countryCode);
            }
        },
        methods: {
            onSubmit() {
                if (this.text === ''){
                    this.error = true;
                }else {
                    this.error = false;
                    this.louder = true;
                    this.resech = true;
                    ApiService.get(`/company/vat/${ this.text }`, ).then((res) => {
                        if(!res.data.Company){
                          this.louder = true;
                            this.company = {
                                id: "1",
                                countryCode : "Sorry",
                                name: "Not Fount",
                                address : "resend request"
                            }
                            ApiService.get(`/company/vat/${ this.text }`, ).then((res) => {
                              if(!res.data.Company){
                                this.louder = false;
                                this.company = {
                                  id: "1",
                                  countryCode : "Sorry",
                                  name: "Not Fount",
                                  address : "resend request"
                                }
                              }
                              this.company = res.data.Company;
                              this.classFlag = this.classFlag + this.flag.toLowerCase();
                              this.louder = false;
                            }).catch((err) =>{
                              this.louder = false;
                              this.company = {
                                id: "1",
                                countryCode : "Sorry",
                                name: "Not Fount",
                                address : "resend request"
                              }
                              //this.louder = false;
                              //console.log(err);
                            })
                        }
                        this.company = res.data.Company;
                        this.classFlag = this.classFlag + this.flag.toLowerCase();
                        this.louder = false;
                    }).catch((err) =>{
                        this.louder = false;
                        this.company = {
                            id: "1",
                            countryCode : "Sorry",
                            name: "Not Fount",
                            address : "resend request"
                        }
                        //this.louder = false;
                        //console.log(err);
                    })

                }

            }
        }
    }
</script>

<style scoped>

</style>
