<template>
    <div class="container mt-4">
        <h2>Search Company data By Name</h2>
        <div class="row center text-center mt-5">
            <div class="">
                <form @submit.prevent="onSubmit" >
                    <div class="input-group mb-3 row center" >
                        <label for="input" class="col-lg-2 col-form-label col-form-label-lg">Company Name</label>
                        <input id="input" type="text" class="form-control form-control-lg col-lg-8" placeholder="Name Research" aria-label="Name Research" aria-describedby="button-addon2" v-model="text">
                        <button class="btn btn-outline-primary col-lg-2" type="submit" id="button-addon2">Search</button>
                    </div>
                </form>
            </div>
        </div>
      <div class="row text-center d-flex justify-content-center"  v-if="!resech">
        <p>
          <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
            Additional Filter
          </button>
        </p>
        <div style="min-height: 120px;">
          <div class="collapse collapse-horizontal row text-center" id="collapseFilter">
            <div class="card card-body m-2 " style="width: 300px;">
              <form>
                <div class="mb-3">
                  <label for="LimtiResult" class="form-label">Limit Result</label>
                  <input type="number" class="form-control" id="LimtiResult" aria-describedby="LimitResultHelper" v-model="limit_filter">
                  <div id="LimitResultHelper" class="form-text">limit a record on result your search</div>
                </div>
              </form>
            </div>
            <div class="card card-body m-2" style="width: 300px;">
              <form>
                <div class="mb-3">
                  <label for="exampleDataList" class="form-label">Search by State Company</label>
                  <input class="form-control" list="countries" id="exampleDataList" placeholder="EU Europe" v-model="state_filter">
                  <datalist id="countries">
                    <option v-for="state in countries "> {{ state.id }} {{ state.verboseName }}</option>
                  </datalist>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        <div class="row text-center d-flex justify-content-center">
            <div class="alert alert-danger" role="alert" v-if="error">
                Attection content not valid! (three characters minimum)
            </div>
        </div>
        <div class="row text-center d-flex justify-content-center" v-if="resech">
            <template v-if="louder">
              <br />
              <div class="row text-center d-flex justify-content-center">
                <div class="col-3">
                  <div class="card" aria-hidden="true">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="card" aria-hidden="true">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="card" aria-hidden="true">
                    <div class="card-body">
                      <h5 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                      </h5>
                      <p class="card-text placeholder-glow">
                        <span class="placeholder col-7"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-6"></span>
                        <span class="placeholder col-8"></span>
                      </p>
                      <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="!louder">
              <div class="row text-center d-flex justify-content-center" v-if="resech">
                <button type="button" class="btn btn-danger btn-lg col-lg-6" @click.prevent="resetSearch">Reset Search</button>
              </div>
              <br />
                <div class="row" v-if="resech">
                    <div class=" mt-2 mb-2">
                        <h5>Filter your List</h5>
                        <div class=" mb-3 ">
                            <h6>Select filter</h6>
                            <select class="form-select" id="inputGroupSelect02" v-model="set_search">
                                <option selected value="0">Choose...</option>
                                <option value="1">Name Company</option>
                                <option value="2">Country</option>
                                <option value="3">Address like</option>
                            </select>
                        </div>
                        <div class="mb-3" v-if="set_search == 1">
                            <label for="filterlist" class="form-label">Search by Names Company</label>
                            <input type="text" class="form-control" id="filterlist" placeholder="Name Company" v-model="searchName">
                        </div>
                        <div class="mb-3" v-if="set_search == 2">
                            <label for="exampleDataList" class="form-label">Search by State Company</label>
                            <input class="form-control" list="countries" id="exampleDataList" placeholder="EU Europe" v-model="state_search">
                            <datalist id="countries">
                                <option v-for="state in countries "> {{ state.id }} {{ state.verboseName }}</option>
                            </datalist>
                        </div>
                      <div class="mb-3" v-if="set_search == 3">
                        <label for="addressDataList" class="form-label">Search by Address</label>
                        <input class="form-control" id="addressDataList" placeholder="Street/ZipCode/City/Region..." v-model="searchAddress">
                      </div>
                    </div>
                </div>
              <template v-if="company.length > 0">
                  <div class="card  m-1 col-md-3 bgInverterblue" v-for="el in filterCompany" :key="el.id" @mouseover="mutedHover( el.id)" @mouseleave="mutedHover(0)">
                      <div class="card-body">
                          <p class=""><span v-bind:class="Flag(el.countryCode)"></span> {{ el.countryCode}}</p>
                          <h5 class="card-title">{{ el.name.toUpperCase()}}</h5>
                          <p class="card-text">{{ el.address}}</p>

                          <template v-if="isHover(el.id)">
                            <a class="btn btn-outline-light" :href="`/company/${el.id}`" >View </a>
                        <!--    <router-link  :to="`/company/${el.id}`" custom v-slot="{ navigate }" class="">
                              <button @click="navigate" @keypress.enter="navigate" class="btn btn-outline-light" type="submit">View</button>
                            </router-link>-->
                          </template>
                          <template v-if="!isHover(el.id)">
                            <a class="btn btn-primary" :href="`/company/${el.id}`" >View </a>
                           <!-- <router-link  :to="`/company/${el.id}`" custom v-slot="{ navigate }" class="">
                              <button @click="navigate" @keypress.enter="navigate" class="btn btn-primary" type="submit">View</button>
                            </router-link> -->
                         </template>
                     </div>
                 </div>
             </template>
               <template  v-if="company.length <= 0">
                   <h6 class="subtitle"> No other Record Result for this Research</h6>
               </template>
           </template>
       </div>
   </div>
</template>

<script>
   import * as ApiService from '@/helpers/api.service'
   import * as Match from '@/helpers/match'
   import * as GetISO from "@/helpers/convertToNationISO";
   export default {
       name: "search",
       props: {
         keySearch: String,
       },
       data() {
           return  {
               louder : false,
               resech : false,
               text:'',
               company: [],
               error : '',
               set_search: '',
               state_search : '',
               searchName : '',
               searchAddress : '',
               hoverEl: 0,
               state_filter : '',
               limit_filter :  1000,
               flagclass : 'flag-icon flag-icon-',
               countries : [
                   {id:"AT", verboseName:"Austria"},
                   {id:"BE", verboseName:"Belgium"},
                   {id:"BG", verboseName:"Bulgaria"},
                   {id:"CY", verboseName:"Cyprus"},
                   {id:"CZ", verboseName:"Czech Republic"},
                   {id:"DE", verboseName:"Germany"},
                   {id:"DK", verboseName:"Denmark"},
                   {id:"EE", verboseName:"Estonia"},
                   {id:"EL", verboseName:"Greece"},
                   {id:"ES", verboseName:"Spain"},
                   {id:"FI" , verboseName:"Finland"},
                   {id:"FR", verboseName:"France"},
                   {id:"GB", verboseName:"United Kingdom"},
                   {id:"HR", verboseName:"Croatia"},
                   {id:"HU", verboseName:"Hungary"},
                   {id:"IE", verboseName:"Ireland"},
                   {id:"IT", verboseName:"Italy"},
                   {id:"LT", verboseName:"Lithuania"},
                   {id:"LU", verboseName:"Luxembourg"},
                   {id:"LV", verboseName:"Latvia"},
                   {id:"MT", verboseName:"Malta"},
                   {id:"NL", verboseName:"The Netherlands"},
                   {id:"PL", verboseName:"Poland"},
                   {id:"PT", verboseName:"Portugal"},
                   {id:"RO", verboseName:"Romania"},
                   {id:"SE", verboseName:"Sweden"},
                   {id:"SI", verboseName:"Slovenia"},
                   {id:"SK", verboseName:"Slovakia"},
               ]
           }
       },
     async mounted() {

     },
     watch : {
       keySearch : function (val, oldVal) {
         if(val !== oldVal) {
           if(this.keySearch !== "" && this.keySearch !== null) {
             this.text = this.keySearch;
             this.onSubmit();
           }
         }
       }
     },
       computed :{
           filterCompany () {
               return this.company.filter((el) => {
                   if( this.set_search == 1) {
                       return el.name.toUpperCase().match(this.searchName.toUpperCase());
                   }
                   else if (this.set_search == 2){
                       return el.countryCode.match(`${this.state_search[0]}${this.state_search[1]}`);
                   }
                   else if( this.set_search == 3) {
                     return el.address.toUpperCase().match(this.searchAddress.toUpperCase());
                   }
                   else {
                       return el;
                   }
               })
           },
       },
       methods: {
           Flag(countryCode) {
               return this.flagclass+GetISO.GetISoCodeNation(countryCode).toLowerCase();
           },
           resetSearch(){
                 this.louder = false;
                 this.resech = false;
                 //this.text='',
                 this.company= [];
                 this.error = '';
                 this.set_search= '';
                 this.state_search = '';
                 this.searchName = '';
                 this.searchAddress = '';
                 this.hoverEl= 0;
                 this.state_filter = '';
                 this.limit_filter =  1000;
                 this.flagclass = 'flag-icon flag-icon-';
           },
           async onSubmit() {
               if (this.text === ''){
                   this.error = true;
               }
               else if (this.text.length < 3){
                 this.error = true;
               }
               else {
                   this.error = false;
                   this.louder = true;
                   this.resech = true;
                   let url = this.state_filter != '' ? `/company/data/search/?name=${this.text}&limit=${parseInt(this.limit_filter)}&country=${this.state_filter[0]}${this.state_filter[1]}` : `/company/data/search/?name=${this.text}&limit=${parseInt(this.limit_filter)}`;
                   await ApiService.get(url).then((res) => {
                     this.company = res.data.Company;
                     this.company = this.company.sort((a, b) => (Match.checkSimilarity(a.name, this.text) < Match.checkSimilarity(b.name, this.text) ? 1 : -1));
                     this.$notify({
                       title: 'Success Search',
                       type : 'success',
                       duration: 10000,
                       speed: 1000,
                       text: `Hi!\n <br> the your search have a ${this.company.length} result`
                     });
                       this.louder = false;
                   }).catch((err) =>{
                   })
               }
           },
           mutedHover(id){
             this.hoverEl = id;
           },
           isHover (id){
            if(id === this.hoverEl){
                return true;
            }
            else {
                return false;
            }
           },
           classColorBtnCss (value) {
               if (value  === true) {
                   return "btn-outline-ligth ";
               }
               else {
                   return "btn-primary";
               }
           },
       }
   }
</script>

<style scoped>

   .bgInverterblue{
       transition: 0.3s;
   }
   .bgInverterblue:hover {
       color: #e3e3e3!important;
       background-color: #0d6efd;
   }

</style>
