
const countriesByEU = [
    {id:"AT", verboseName:"Austria"},
    {id:"BE", verboseName:"Belgium"},
    {id:"BG", verboseName:"Bulgaria"},
    {id:"CY", verboseName:"Cyprus"},
    {id:"CZ", verboseName:"Czech Republic"},
    {id:"DE", verboseName:"Germany"},
    {id:"DK", verboseName:"Denmark"},
    {id:"EE", verboseName:"Estonia"},
    {id:"EL", verboseName:"Greece"},
    {id:"ES", verboseName:"Spain"},
    {id:"FI" , verboseName:"Finland"},
    {id:"FR", verboseName:"France"},
    {id:"GB", verboseName:"United Kingdom"},
    {id:"HR", verboseName:"Croatia"},
    {id:"HU", verboseName:"Hungary"},
    {id:"IE", verboseName:"Ireland"},
    {id:"IT", verboseName:"Italy"},
    {id:"LT", verboseName:"Lithuania"},
    {id:"LU", verboseName:"Luxembourg"},
    {id:"LV", verboseName:"Latvia"},
    {id:"MT", verboseName:"Malta"},
    {id:"NL", verboseName:"The Netherlands"},
    {id:"PL", verboseName:"Poland"},
    {id:"PT", verboseName:"Portugal"},
    {id:"RO", verboseName:"Romania"},
    {id:"SE", verboseName:"Sweden"},
    {id:"SI", verboseName:"Slovenia"},
    {id:"SK", verboseName:"Slovakia"},
];

exports.GetISoCodeNation = (IdNaEu) => {
  if ( IdNaEu === 'EL') {
      return "GR";
  }
  else {
      return IdNaEu;
  }

};
